import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getData } from "../../utils/db";

// for member list
export const getMemberList = createAsyncThunk(
    'member/getMemberList',
    async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}v2/club/getAllMemeberList`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getData().token}` // Assuming the token is retrieved using getData function
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Something went wrong');
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);


// for manage member
export const manageMember = createAsyncThunk(
    '/member/manageMember', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('v2/club/manageMember', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const removeMember = createAsyncThunk(
    '/member/removeMember', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('v1/club/leave_club', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.error)
        }
    }
)


export const userForAddMember = createAsyncThunk(
    '/member/userForAddMember', async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/chat/show_member`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getData().token}` // Assuming the token is retrieved using getData function
                },
                body: JSON.stringify(data)
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Something went wrong');
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const joinClub = createAsyncThunk(
    '/club/joinClub', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('v1/club/join_club', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const viewMember = createAsyncThunk(
    '/club/viewMember', async (id, { rejectWithValue }) => {
        try {
            const response = await axios(`v2/club/getById/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const removeMultipleMember = createAsyncThunk(
    '/club/removeMultipleMember', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('v2/club/removeToClub', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)
const memberSlice = createSlice({
    name: 'member',
    initialState: {
        memberList: [],
        total: 0,
        users: [],
        member: {},
        error: ''
    },
    reducers: {
        removeMemberFromList: (state, action) => {
            const membersToRemove = state.memberList.filter(
                (member) =>
                    member?.club_id === action.payload.clubId &&
                    member?.user_id?._id === action.payload.id
            );

            // Filter out the members that match the criteria
            state.memberList = state.memberList.filter(
                (member) =>
                    !membersToRemove.some(
                        (toRemove) => toRemove._id === member._id
                    )
            );
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMemberList.fulfilled, (state, action) => {
                state.memberList = action.payload.data.clubMember
                state.total = action.payload.data.total
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(getMemberList.rejected, (state, action) => {
                state.error = action.payload
                state.memberList = []
                state.total = 0
            })
            .addCase(manageMember.fulfilled, (state, action) => {
                state.memberList = state.memberList.map((data) => {
                    if (data?._id === action.payload.data._id) {
                        return {
                            ...action.payload.data
                        }
                    }
                    return { ...data }
                })
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(userForAddMember.pending, (state, action) => {
                state.error = ''
            })
            .addCase(userForAddMember.fulfilled, (state, action) => {
                state.users = action.payload.data.data
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(userForAddMember.rejected, (state, action) => {
                state.users = []
                state.error = action.payload
            })
            .addCase(joinClub.fulfilled, (state, action) => {
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(joinClub.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(viewMember.fulfilled, (state, action) => {
                state.member = action.payload.data
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(viewMember.rejected, (state, action) => {
                state.error = action.payload
                state.member = {}
            })

    }
})


export const { removeMemberFromList } = memberSlice.actions

export default memberSlice.reducer