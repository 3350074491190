import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Piechart from './Piechart';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../utils/db';
import { useNavigate } from 'react-router-dom/dist/umd/react-router-dom.development';
import { getAllContsAndAlert, getCheckInUser, getRecentActivity, wether } from '../../store/slice/dashboardSlice';
import activemember from '../../Assest/activemember.png';
import recentpost from '../../Assest/recentpost.png';
import killsicon from '../../Assest/killsicon.png';
import Alertdetailsmodal from '../Alertdetailsmodal/Alertdetailsmodal';
import { Downangle, LocationIcon, OpenEye, Rightarrow, Upangle, WindIcon } from '../../Assest/Allsvg';
import MapComponentDashboard from '../mapComponent/mapComponentDashboard';
import Header from '../Header/Header';

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(5);
  const [userName, setUserName] = useState('');
  const [alertDetail, setAlertDetail] = useState(null);
  const clubId = useSelector((state) => state.dashboard.club_id);
  const alert = useSelector((state) => state.dashboard.alert);
  const counts = useSelector((state) => state.dashboard.counts);
  const activites = useSelector((state) => state.dashboard.activites);
  const area = useSelector((state) => state.dashboard.area);
  const checkIn = useSelector((state) => state.dashboard.checkInUsers);
  const wetherData = useSelector((state) => state.dashboard.wether)
  const clubLocation = useSelector((state) => state.dashboard.location)
  useEffect(() => {
    let data = getData();
    let club_id = clubId ? clubId : data?.club_id
    if (club_id) {
      dispatch(getAllContsAndAlert({ start, limit, club_id }))
      dispatch(getRecentActivity({ club_id, start, limit }))
      dispatch(getCheckInUser(club_id))
      if (clubLocation) {
        dispatch(wether({ city: clubLocation }))
      } else {
        dispatch(wether({ city: data?.location }))
      }
    }
    setUserName(data?.user?.username);
  }, [dispatch, start, limit, clubId, clubLocation]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (data) => {
    setIsModalOpen(true);
    setAlertDetail(data)
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setAlertDetail(null)
  };
  function formatNumber(num) {
    if (num >= 1000000000) {
      return (num / 1000000000)?.toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
      return (num / 1000000)?.toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000)?.toFixed(1).replace(/\.0$/, '') + 'k';
    }
    return num;
  }
  const items = [
    {
      heading: "Check-in members",
      totaluser: activemember,
      subheading: counts?.activeMemeber ? formatNumber(counts?.activeMemeber) : 0
    },
    {
      heading: "Total posts", totaluser: recentpost, subheading: counts?.post ? formatNumber(counts?.post) : 0,
    },
    {
      heading: "Total kills", totaluser: killsicon, subheading: counts?.kills ? formatNumber(counts?.kills) : 0,
    }
  ];
  const mapContainerStyle = {
    height: '400px',
    width: '100%'
  }
  const mediaQuery = {
    width: '100%',
    height: '400px'
  }
  const handleOpenRecentActivity = () => {
    navigate('/dashboard/recentactivity')
  }
  const handleOpenAlert = () => {
    navigate('/dashboard/alertsviewmore')
  }
  return (
    <>
      <Header title="Dashboard" />
      <div className='flex flex-col overflow-auto h-[calc(100vh_-_81px)] p-4 sm:px-5 sm:py-6 sm:h-[calc(100vh_-_97px)]'>
        <div className='block md:hidden pb-4' >
          <Link href="#" className='text-dark-black text-base font-bold md:text-xl xl:text-2xl'>Dashboard</Link>
          {/* <span className='block'>Welcome {userName}</span> */}
        </div>
        <div className="flex flex-col 2xl:flex-row gap-5 sm:gap-7">
          <div className='w-full 2xl:w-[calc(100%_-_383px)]'>
            <div className="grid sm:grid-cols-2 col-span-2 gap-4 lg:gap-6 xl:grid-cols-3">
              {items?.map((item, index) => (
                <div className="col-span-1" key={index}>
                  <div className='border-b-4 border-camo-green bg-white relative pt-4 p-4 pr-4 rounded-[10px] md:border-b-0 
                    md:border-l-4 md:pl-5'>
                    <div className='relative z-10'>
                      <div className='flex flex-col items-start justify-between md:flex-row md:items-center'>
                        <h4 className='text-cloud-gray text-sm font-medium lg:text-lg'>{item?.heading}</h4>
                        <div className='border border-camo-green w-[42px] h-[42px] items-center justify-center rounded-lg bg-amour-gray hidden md:flex'>
                          <img src={item?.totaluser} alt="" className='w-6' /></div>
                      </div>
                      <div className='flex items-end justify-between pt-2'>
                        <div className='flex items-center justify-between pt-4 gap-2'>
                          <h3 className='text-dark-black text-lg font-bold lg:text-xl'>{item?.subheading}</h3>
                          <span className='flex items-center text-cloud-gray text-sm font-bold gap-2'><Rightarrow className="w-5" />{item?.name}</span>
                        </div>
                        <div className='flex border border-camo-green bg-amour-gray p-[6px] rounded-lg md:hidden md:p-2'>
                          <img src={item?.totaluser} alt="" className='w-6' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="mt-5 gap-4 sm:gap-6 md:mt-7">
              <div className='flex flex-col bg-white p-4 md:pt-5  md:pb-9 md:px-5 rounded-lg md:p-5'>
                <h4 className='text-dark-black text-sm font-bold pb-3 md:text-xl'>| Outdoor hunt Overview</h4>
                <MapComponentDashboard markers={checkIn} polyog={area?.lat_long1} mapMax={mapContainerStyle} small={mediaQuery} />
              </div>
            </div>
          </div>
          <div className='w-full 2xl:w-[355px] flex flex-col sm:flex-row 2xl:flex-col gap-4 sm:gap-7'>
            <div className='sm:w-1/2 2xl:w-full bg-white rounded-[10px] py-4 px-[14px] text-center flex flex-col'>
              {
                wetherData ?
                  <>
                    <div className='flex-1 flex flex-col justify-center' >
                      <h3 className='text-3xl md:text-[42px] text-camo-green font-semibold'>{wetherData?.temp}<sup>°C</sup></h3>
                      <div className='flex justify-center items-center gap-2 pt-2'>
                        <span className='flex items-center gap-2 text-[#7D7D7D] font-semibold border-r-'><Upangle /> {wetherData?.tempmax}°</span>
                        <div className='bg-[#CBCBCB] h-4 w-[2px] rounded'></div>
                        <span className='flex items-center gap-2 text-[#7D7D7D] font-semibold'><Downangle />{wetherData?.tempmin}°</span>
                      </div>
                      <h6 className='flex items-center justify-center gap-2 pt-3 sm:pt-4 text-dark-black font-semibold'> <LocationIcon /> {getData()?.location || ''}</h6>
                    </div>
                    <div className='bg-[#FAFAFA] py-3 sm:py-4 px-[14px] rounded-md flex items-center justify-between mt-4 sm:mt-6'>
                      <div className='flex gap-2 items-center border-r border-r-[rgba(203, 203, 203, 0.5)] pr-[10px]'>
                        <WindIcon />
                        <span className='text-start text-cloud-gray font-semibold text-xs'>Wind
                          <b className='block font-bold text-camo-green text-[13px]'>{wetherData?.windspeed} mph</b>
                        </span>
                      </div>
                      <div className='flex gap-2 items-center border-r border-r-[rgba(203, 203, 203, 0.5)] pr-[10px]'>
                        <WindIcon />
                        <span className='text-start text-cloud-gray font-semibold text-xs'>Humidity
                          <b className='block font-bold text-camo-green text-[13px]'>{wetherData?.humidity}%</b>
                        </span>
                      </div>
                      <div className='flex gap-2 items-center'>
                        <WindIcon />
                        <span className='text-start text-cloud-gray font-semibold text-xs'>Perception
                          <b className='block font-bold text-camo-green text-[13px]'>{wetherData?.precipprob}%</b>
                        </span>
                      </div>
                    </div>
                  </>
                  : <p>Loading wether....</p>
              }
            </div>
            <div className='sm:w-1/2 2xl:w-full flex flex-col bg-white p-4 md:pt-5  md:pb-9 md:px-5 rounded-lg md:p-5'>
              <h4 className='text-dark-black text-sm font-bold pb-2 md:text-xl'>| Total Member</h4>
              <Piechart checkIn={counts?.checkIn || 0} checkout={counts?.checkout || 0} counts={counts?.totalMember} />
              <div className='flex justify-center items-center pt-5 gap-2 md:pt-7 lg:items-start'>
                <div className='flex items-center gap-[10px] radio-btn'>
                  <span className='border-2 border-white bg-camo-green w-[14px] h-[14px] rounded-full shadow-[0_0_8px_rgba(13_10__44_0.8)]'></span>
                  <span className='block text-dark-black text-sm font-medium'>Check-In Member</span>
                </div>
                <div className='flex items-center gap-[10px] radio-btn'>
                  <span className='border-2 border-white bg-[#7F8D72] w-[14px] h-[14px] rounded-full shadow-[0_0_8px_rgba(13_10__44_0.8)]'>
                  </span>
                  <span className='block text-dark-black text-sm font-medium'>Check-Out Member</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 2xl:grid-cols-2 gap-7'>
          <div className="hidden bg-white p-5 mt-7 rounded-lg md:block">
            <div className='flex items-center justify-between text-lg pb-3 sm:pb-5'>
              <h4 className='text-dark-black text-sm font-bold md:text-xl'>| Recent Activity</h4>
              <button className='text-cloud-gray text-sm font-semibold md:text-base' onClick={handleOpenRecentActivity}>View More</button>
            </div>
            <div className='overflow-auto' >
              <table className="min-w-full ">
                <thead>
                  <tr>
                    <th className="bg-white-lilac text-[#363636] text-start text-base font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tl-lg rounded-l-lg leading-normal">Member</th>
                    <th className="bg-white-lilac text-[#363636] text-start text-base font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Activity</th>
                    <th className="bg-white-lilac text-[#363636] text-start text-base font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Create Date/Time</th>
                    {/* <th className="bg-white-lilac text-camo-green text-sm text-center font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tr-lg rounded-br-lg leading-normal">Action</th> */}
                  </tr>
                </thead>
                {activites?.length > 0 ? activites.map((data, index) => (
                  <tr key={index}>
                    <td className="py-2 px-3 md:px-4 md:py-3 flex gap-[10px] items-center">
                      <img src={data?.user?.profile_img ? data?.user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt='' className='rounded-full h-7 w-7 flex-shrink-0'></img>
                      <h6 className='text-sm font-semibold capitalize text-[#363636] text-ellipsis overflow-hidden whitespace-nowrap w-[80px]'>{data?.user?.name}</h6>
                    </td>
                    <td className="py-2 px-3 md:px-4 md:py-3 whitespace-nowrap">
                      <span className={`border rounded-md py-[6px] px-4 text-sm font-medium capitalize ${data.type === 'Check-Out' ? ' border-[#EB5757] bg-[#EB5757]/10 text-[#EB5757]' : data?.type === 'Check-In' ? 'border-[#219653] bg-[#219653]/10 text-[#219653]' : data?.type === "Club notify" ? 'border-[#3890EE] bg-[#3890EE]/10 text-[#3890EE]' : data?.type === "Add Club Post" ? 'border-[#F8258A] bg-[#F8258A]/10 text-[#F8258A]' : 'border-[#EF7632] bg-[#EF7632]/10 text-[#EF7632]'}`} >{data.type === 'Add Club Post' ? 'Added Post' : data?.type === 'Add Member' ? 'Added Member' : data.type}</span>
                    </td>
                    <td className="py-2 px-3 md:px-4 md:py-3 whitespace-nowrap">
                      <span className='text-[#686868] font-semibold text-sm' >{moment(data.createdAt).format('DD MMM, YYYY hh:mm A')}</span>
                    </td>
                    {/* <td className="py-2 px-3 md:px-4 md:py-3 whitespace-nowrap text-center">
                      <Link href="#" ><OpenEye className='m-auto w-5' /></Link>
                    </td> */}
                  </tr>
                )) : (
                  <tr>
                    <td colSpan="5" className="text-center py-4 font-medium text-[#363636]">No activites available</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
          <div className="hidden bg-white p-5 2xl:mt-7 rounded-lg md:block">
            <div className='flex items-center justify-between text-lg pb-3 sm:pb-5'>
              <h4 className='text-dark-black text-sm font-bold md:text-xl'>| Alerts & Announcements</h4>
              <button className='text-cloud-gray text-sm font-semibold md:text-base' onClick={handleOpenAlert}>View More</button>
            </div>
            <div className='overflow-auto'>
              <table className="min-w-full ">
                <thead>
                  <tr>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tl-lg rounded-l-lg leading-normal">Member</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Title</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Description</th>
                    <th className="bg-white-lilac text-camo-green text-start text-sm font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Create Date/Time</th>
                    <th className="bg-white-lilac text-camo-green text-sm text-center font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tr-lg rounded-br-lg leading-normal">View</th>
                  </tr>
                </thead>
                <tbody>
                  {alert?.length > 0 ? alert.map((data, index) => (
                    <tr key={index}>
                      <td className="py-2 px-3 md:py-3 whitespace-nowrap flex gap-[10px] justify-center items-center">
                        <img src={data?.action_user?.profile_img ? data?.action_user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt='' className='rounded-full  h-7 w-7 flex-shrink-0'></img>
                        <h6 className='text-[#363636] font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap w-[100px]' >{data?.action_user?.name}</h6>
                      </td>
                      <td className="py-2 px-3 md:py-3 whitespace-nowrap">
                        <h5 className='text-[#363636] font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap w-[130px]'>
                          {data.title}
                        </h5>
                      </td>
                      <td className="py-2 px-3 md:py-3 whitespace-nowrap">
                        <p className='text-[#363636] text-sm font-medium capitalize text-ellipsis overflow-hidden whitespace-nowrap w-[130px]' >{data.discription}</p>
                      </td>
                      <td className="py-2 px-3 md:py-3 whitespace-nowrap">
                        <span className='text-[#686868] text-sm font-semibold' >{moment(data.createdAt).format('DD MMM, YYYY hh:mm A  ')}</span>
                      </td>
                      <td className="py-2 px-3 md:py-3 whitespace-nowrap text-center">
                        <Link href="#" onClick={() => handleOpenModal(data)}><OpenEye className='m-auto w-5' /></Link>
                      </td>
                    </tr>
                  )) : (
                    <tr>
                      <td colSpan="5" className="text-center py-4 font-medium text-[#363636]">No alerts available</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <Alertdetailsmodal open={isModalOpen} handleClose={handleCloseModal} details={alertDetail} />
            </div>
          </div>
        </div>
        <div className='block bg-white p-4 sm:p-5 mt-4 sm:mt-5 rounded-[10px] md:hidden'>
          <div className='flex flex-col text-lg pb-3 sm:pb-5'>
            <div className='flex items-center justify-between text-lg pb-7'>
              <h4 className='text-dark-black text-sm font-bold md:text-xl'>| Recent Activity</h4>
              <button className='text-cloud-gray text-sm font-semibold md:text-base' onClick={handleOpenRecentActivity}>View More</button>
            </div>
            {activites?.length > 0 ? activites.map((data, index) => (
              <div key={index} className='border-b md:border border-amour-gray pb-4 sm:pb-5 md:p-4 sm:mb-5 md:mb-0 gap-3 sm:gap-4 md:gap-6 flex justify-center flex-col usergridlist mb-4'>
                <div className='flex justify-between items-center gap-2 text-dark-black font-semibold text-sm md:text-base'>
                  <div className='flex items-center gap-3'>
                    <img src={data?.action_user?.profile_img ? data?.action_user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt='' className='rounded-full  h-7 w-7 flex-shrink-0'></img>
                    <h6 className='text-[#363636] font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap w-[100px]' >{data?.action_user?.name}</h6>
                  </div>
                </div>
                <table>
                  <tbody className='flex justify-center flex-col gap-3'>
                    <tr className='flex sm:items-center flex-wrap gap-2 md:gap-3'>
                      <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Activity<span>:</span></td>
                      <td>
                        <span className={`border rounded-md py-[6px] px-4 text-sm font-medium capitalize ${data.type === 'Check-Out' ? ' border-[#EB5757] bg-[#EB5757]/10 text-[#EB5757]' : data?.type === 'Check-In' ? 'border-[#219653] bg-[#219653]/10 text-[#219653]' : data?.type === "Club notify" ? 'border-[#3890EE] bg-[#3890EE]/10 text-[#3890EE]' : data?.type === "Add Club Post" ? 'border-[#F8258A] bg-[#F8258A]/10 text-[#F8258A]' : 'border-[#EF7632] bg-[#EF7632]/10 text-[#EF7632]'}`} >{data.type === 'Add Club Post' ? 'Added Post' : data?.type === 'Add Member' ? 'Added Member' : data.type}</span>
                      </td>
                    </tr>
                    <tr className='flex sm:items-center flex-wrap gap-2 md:gap-3'>
                      <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Create Date/Time<span>:</span></td>
                      <td>
                        <span className='text-[#686868] text-sm font-semibold' >{moment(data.createdAt).format('DD MMM, YYYY hh:mm A  ')}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )) : (<div className='col-span-4'>
              <p className='text-center text-sm text-cloud-gray font-semibold'>Data not found</p>
            </div>
            )}
          </div>
        </div>
        <div className='block bg-white p-4 sm:p-5 mt-4 sm:mt-5 rounded-[10px] md:hidden'>
          <div className='flex flex-col text-lg pb-3 sm:pb-5'>
            <div className='flex items-center justify-between text-lg pb-7'>
              <h4 className='text-dark-black text-sm font-bold md:text-xl'>| Alerts & Announcements</h4>
              <button className='text-cloud-gray text-sm font-semibold md:text-base' onClick={handleOpenAlert}>View More</button>
            </div>
            {alert?.length > 0 ? alert.map((data, index) => (
              <div key={index} className='border-b md:border border-amour-gray pb-4 sm:pb-5 md:p-4 sm:mb-5 md:mb-0 gap-3 sm:gap-4 md:gap-6 flex justify-center flex-col usergridlist'>
                <div className='flex justify-between items-center gap-2 text-dark-black font-semibold text-sm md:text-base'>
                  <div className='flex items-center gap-3'>
                    <img src={data?.action_user?.profile_img ? data?.action_user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt='' className='rounded-full  h-7 w-7 flex-shrink-0'></img>
                    <h6 className='text-[#363636] font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap w-[100px]' >{data?.action_user?.name}</h6>
                  </div>
                </div>
                <table>
                  <tbody className='flex justify-center flex-col gap-3'>
                    <tr className='flex sm:items-center flex-wrap gap-1 sm:gap-2 md:gap-3'>
                      <td className='flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green word-break'>Tittle<span>:</span>
                      </td>
                      <td>
                        <h5 className='text-[#363636] font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap w-[130px]'>
                          {data.title}
                        </h5>
                      </td>
                    </tr>
                    <tr className='flex sm:items-center gap-2 md:gap-3'>
                      <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Description<span>:</span></td>
                      <td>
                        <p className='text-[#363636] text-sm font-medium capitalize text-ellipsis overflow-hidden whitespace-nowrap w-[130px]' >{data.discription}</p>
                      </td>
                    </tr>
                    <tr className='flex sm:items-center gap-2 md:gap-3'>
                      <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Create Date/Time<span>:</span></td>
                      <td>
                        <span className='text-[#686868] text-sm font-semibold' >{moment(data.createdAt).format('DD MMM, YYYY hh:mm A  ')}</span>
                      </td>
                    </tr>
                    <tr className='flex sm:items-center gap-2 md:gap-3'>
                      <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>
                        View
                      </td>
                      <td>
                        <Link href="#" onClick={() => handleOpenModal(data)}><OpenEye className='m-auto w-5' /></Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )) : (<div className='col-span-4'>
              <p className='text-center text-sm text-cloud-gray font-semibold'>Data not found</p>
            </div>
            )}
          </div>
          <Alertdetailsmodal open={isModalOpen} handleClose={handleCloseModal} details={alertDetail} />
        </div>
      </div>
      <Alertdetailsmodal open={isModalOpen} handleClose={handleCloseModal} details={alertDetail} />
    </>
  )
}
export default Dashboard;