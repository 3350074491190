import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './slice/adminSlice';
import dashboardSlice from './slice/dashboardSlice';
import memberSlice from './slice/memberSlice';
import chatSlice from './slice/chatSlice';

const store = configureStore({
  reducer: {
    admin: adminReducer,
    dashboard: dashboardSlice,
    member: memberSlice,
    chat: chatSlice
  },
});

export default store