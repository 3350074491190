export const validateEmail = (email) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
        return false; // Fails basic email format check
    }

    // Extract domain part and ensure it ends with .com
    const domain = email.split('@')[1];
    if (!domain.endsWith('.com')) {
        return false; // Domain must end with .com
    }

    const domainParts = domain.split('.');

    // Since .com is required and must only occur once at the end, ensure no other part is 'com'
    if (domainParts.slice(0, -1).includes('com')) {
        return false; // '.com' occurs more than once
    }

    // Additional check for parts length before the '.com'
    if (domainParts.some((part, index) => index < domainParts.length - 1 && part.length > 10)) {
        return false; // Any part other than TLD exceeds 10 characters
    }

    return true; // Passes all checks
}

export const validatePhone = (phone) => {
    // This example regex matches US phone numbers. Adjust according to your needs.
    const re = /^\+?([1-9]{1,3})?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return re.test(phone);
};

export const validateName = (name) => {
    const basicValidationRegex =/^[A-Za-z\s\d_.@-]{2,30}$/;
    if (!basicValidationRegex.test(name)) {
        return false; // Fails basic validation
    }

    // Check for repeated character
    for (let i = 1; i < name.length; i++) {
        if (name[i] !== name[0]) {
            return true; // Passes validation (found a different character)
        }
    }
    return false; // Fails validation (all characters are the same)
};
export const validateDescription = (name) => {
    const re = /^[A-Za-z\s+\d]{2,200}$/;
    return re.test(name);
};


export const validateUsername = (username) => {
    const regex = /^\S+$/;

    // Check if username matches the regular expression
    if (!regex.test(username)) {
        return false
    }

    // Username is valid
    return true
}

export const isValidTime = (time) => {
    const regex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
    return regex.test(time);
};