import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../Header/Header';
import { viewMember } from '../../store/slice/memberSlice';
import { getData } from '../../utils/db';
import { Leftangle, Messages } from '../../Assest/Allsvg';
import MapComponent from '../mapComponent/mapCompnent';
import Removemember from '../Removemember/Removemember';

const Viewmember = () => {
    const dispatch = useDispatch()
    const member = useSelector((state) => state.member.member);
    const checkInMembers = useSelector((state) => state.dashboard.checkInUsers);
    const [checkInMember, setCheckInMember] = useState(null);
    useEffect(() => {
        let id = getData()?.memberId
        if (id) {
            dispatch(viewMember(id))
        }
    }, [dispatch])
    let club
    const [lat_long, setLat_long] = useState(null)
    const [lat_long1, setLat_long1] = useState(null)
    useEffect(() => {
        if (member) {
            setLat_long1(member.area?.lat_long1)
            setId(member?.user_id?._id)
            if (member.checkIn?.status === 1) {
                setCheckInMember(member?.checkIn)
                setLat_long(member.checkIn.latLong?.coordinates)
            }
        }
        if (checkInMember?.length > 0) {
            for (let index = 0; index < checkInMember.length; index++) {
                const element = checkInMember[index];
                if (element?.club_id?.toString() === getData()?.club_id?.toString() && element?.user_id?._id?.toString() === member?.user_id?._id?.toString()) {
                    setLat_long(element?.latLong)
                    setCheckInMember(element)
                }
            }
        }
    }, [member, checkInMembers]);
    // const [open, setOpen] = React.useState(false);
    // const theme = useTheme();
    // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    // const handleClose = () => {
    //     setOpen(false);
    // };
    const mapContainerStyle = {
        height: '400px',
        width: '70%'
    }
    const mediaQuery = {
        width: '100%',
        height: '400px'
    }
    const [Id, setId] = useState(null)
    const [Deletemember, setIsDeletemember] = useState(false);
    const handleDeleteMember = (e) => {
        setIsDeletemember(true);
    };
    const handleCloseMember = () => {
        setIsDeletemember(false);
    };
    return (
        <>
            <Header title="Club Management" />
            <div className='flex flex-col flex-1 p-4 sm:py-6 sm:px-5  h-[calc(100%_-_81px)] md:h-[calc(100%_-_97px)]'>
                <div className='flex flex-col bg-white overflow-auto p-4 sm:p-5 rounded-[10px] h-full md:flex-1 md:bg-transparent md:p-0'>
                    <h4 className='text-sm md:text-xl text-dark-black font-bold flex items-center gap-2 sm:gap-5 pb-2 sm:pb-4'>
                        <Link href="#" to="/member" ><Leftangle className="w-5 sm:w-6" /></Link >| View Member</h4>
                    <div className='flex flex-col overflow-auto rounded-[10px] pt-0 md:flex-1 md:bg-white md:p-5'>
                        <div className='hidden justify-between items-start pb-5 md:flex'>
                            <div className='flex flex-col items-center bg-light-gray gap-4 rounded-lg md:flex-row md:bg-transparent'>
                                <img src={member?.user_id?.profile_img ? member?.user_id?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/gwkKVE5715Im7ymb.jpg"} alt="" className='w-[80px] h-[80px] rounded-full object-cover' />
                                {/* <h5 className='text-sm font-semibold md:text-lg lg:text-xl'>{member?.user_id?.username}</h5> */}
                            </div>
                            <div className='flex items-center gap-2'>
                                <Link href="#" to="/member/messages" className='border border-[#3890ee33] bg-[#3890ee1a] p-[10px] rounded-lg'><Messages className="fill-[#5896F3]" /></Link>
                            </div>
                        </div>
                        <div className='flex flex-col border-t border-t-amour-gray pt-4 rounded-lg gap-4 md:border-none md:flex-1 md:pt-0 md:gap-6'>
                            <div className='flex items-center text-dark-black text-sm font-semibold gap-2 md:hidden'>

                                <img className='w-9 h-9 rounded-full object-cover' src={member?.user_id?.profile_img ? member?.user_id?.profile_img : 'https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/gwkKVE5715Im7ymb.jpg' } alt="" />{member?.user_id?.username}
                            </div>
                            <table className='flex flex-col gap-3'>
                                <tbody className='flex flex-col justify-center gap-[6px] md:gap-3 '>
                                    <tr className='flex flex-wrap items-center gap-1 md:gap-3'>
                                        <td className='flex justify-between items-center text-cloud-gray font-semibold md:min-w-[160px] text-lg md:leading-[normal]'>Member name <span>:</span></td>
                                        <td className='text-dark-black font-semibold text-base md:leading-[normal]'>{member?.user_id?.name}</td>
                                    </tr>
                                    <tr className='flex flex-wrap items-start gap-1 md:flex-nowrap md:gap-3'>
                                        <td className='flex justify-between text-lg items-center text-cloud-gray md:min-w-[160px] font-semibold md:leading-[normal]'>Member ID <span>:</span></td>
                                        <td className='text-dark-black font-semibold max-w-[450px] md:leading-[normal]'>@{member?._id}</td>
                                    </tr>
                                    <tr className='md:flex items-center gap-1 md:gap-6 hidden'>
                                        <td className='md:min-w-[160px] flex justify-between items-center text-xs md:text-lg font-semibold text-cloud-gray'>Status <span>:</span></td>
                                        <td>
                                            {
                                                member?.checkIn?.status === 1 ?
                                                    <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green font-semibold text-sm'>Active</button>
                                                    :
                                                    <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-red font-semibold text-sm'>In-Active</button>

                                            }
                                            {/* <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green-grey font-semibold text-sm'>InActive</button> */}
                                        </td>
                                    </tr>
                                    <tr className='flex flex-wrap items-center gap-1 md:gap-3'>
                                        <td className='flex justify-between items-center text-[#363636] text-lg font-bold md:min-w-[160px] md:leading-[normal]'>Member Tracking <span>:</span></td>
                                        <td className='text-dark-black text-xs md:text-sm md:leading-[normal]'>{club?.maxCheckInTime}</td>
                                    </tr>
                                </tbody>
                                <MapComponent marker={lat_long} polyog={lat_long1} mapMax={mapContainerStyle} small={mediaQuery} member={checkInMember} />
                            </table>
                        </div>
                        <div className='flex items-center pt-5 md:mx-auto gap-[10px] md:hidden'>
                            <Link onClick={handleDeleteMember} className='flex items-center justify-center bg-red-orange text-white 
                            text-sm font-semibold py-3 px-10 rounded-md w-1/2 md:min-w-[144px]'>
                                Delete
                            </Link>
                        </div>
                        <Removemember open={Deletemember} handleClose={handleCloseMember} id={Id} type={1} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Viewmember;