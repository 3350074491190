import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

class Piechart extends React.Component {
  render() {
    const { checkIn, checkout, counts } = this.props;
    const data = [
      { name: 'Members', value: parseFloat(checkIn.toFixed(2)) },
      { name: 'Remaining', value: parseFloat(checkout.toFixed(2)) },
    ];
    const COLORS = ['#4A5A3C', '#A2B3A1'];
    return (  
      <div style={{ position: 'relative', width: 200, height: 200, margin: 'auto' }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              dataKey="value"
              innerRadius={78}
              outerRadius={100}
              startAngle={90}
              endAngle={450}
              paddingAngle={0}
              isAnimationActive={false}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} cornerRadius={10} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
        }}>
          <div style={{ fontSize: '24px', fontWeight: 'bold' }}>{counts}</div>
          <div style={{ fontSize: '16px', color: '#555' }}>All Member</div>
        </div>
      </div>
    );
  }
}

Piechart.propTypes = {
  checkIn: PropTypes.number,
  checkout: PropTypes.number,
};

export default Piechart;