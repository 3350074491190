import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { addData } from "../../utils/db";


export const adminLogin = createAsyncThunk(
    'admin/adminLogin',
    async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/auth/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const resData = await response.json();

            if (resData.success) {
                addData(resData.data.token);
                let user;
                if (data.isCheck) {
                    user = {
                        email: data?.email,
                        password: data?.password,
                        name: resData.data.user.name,
                        _id: resData.data.user._id,
                        username: resData.data.user.username,
                        profile_img: resData.data.user.profile_img,
                        isCheck: data?.isCheck
                    };
                } else {
                    user = {
                        email: null,
                        password: null,
                        name: resData.data.user.name,
                        _id: resData.data.user._id,
                        username: resData.data.user.username,
                        profile_img: resData.data.user.profile_img,
                        isCheck: data?.isCheck
                    };
                }
                addData(null, user);
            }

            return resData;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);


// for social login
export const socialLogin = createAsyncThunk(
    '/admin/socialLogin', async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/auth/social_login_for_club_penal`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const resData = await response.json();
            if (resData.success) {
                addData(resData.data.token);
                let user;
                if (data.isCheck) {
                    user = {
                        email: data?.email,
                        password: data?.password,
                        name: resData.data.user.name,
                        _id: resData.data.user._id,
                        username: resData.data.user.username,
                        profile_img: resData.data.user.profile_img,
                        isCheck: data?.isCheck
                    };
                } else {
                    user = {
                        email: null,
                        password: null,
                        name: resData.data.user.name,
                        _id: resData.data.user._id,
                        username: resData.data.user.username,
                        profile_img: resData.data.user.profile_img,
                        isCheck: data?.isCheck
                    };
                }
                addData(null, user);
            }

            return resData;
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        admin: null,
        error: ''
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(adminLogin.fulfilled, (state, action) => {
                state.admin = action.payload.data.user
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(adminLogin.rejected, (state, action) => {
                state.error = action.payload
                state.admin = null
            })
            .addCase(socialLogin.fulfilled, (state, action) => {
                state.admin = action.payload.data.user
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(socialLogin.rejected, (state, action) => {
                state.error = action.payload
                state.admin = null
            })
    }
})

export default adminSlice.reducer