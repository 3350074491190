import React from 'react';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { Closeicon } from '../../Assest/Allsvg';
import moment from 'moment';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const Alertdetailsmodal = ({ open, handleClose, details }) => {
    const timeAgo = moment(details?.createdAt).fromNow();
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='w-[95%] sm:w-[620px] rounded-[15px] pt-3 pb-5 px-4 sm:px-6'>
                    <div className='flex items-center justify-between pb-3'>
                        <h6 id="modal-modal-title" className='text-[#363636] text-xl font-semibold'>
                            Alerts & Announcements Details
                        </h6>
                        <button onClick={handleClose}>
                            <Closeicon />
                        </button>
                    </div>
                    <div className='border border-[#EEEEEE] pt-3 pb-5 px-4 sm:px-[26px] rounded-2xl'>
                        <span className='block text-[#363636] font-medium text-end'>{timeAgo}</span>
                        <div className='flex items-center gap-[10px]'>
                            <img src={details?.action_user?.profile_img} alt="" className='w-10 h-10 rounded-full object-cover' />
                            <h3 className='text-[#363636] font-bold'>{details?.action_user?.name}</h3>
                        </div>
                        <h6 className='text-[#363636] font-semibold pt-2'>{details?.title} </h6>
                        <p className='text-[#363636] font-medium overflow-hidden h-[95px] text-ellipsis line-clamp-4'>{details?.discription}</p>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default Alertdetailsmodal;