import React, { useEffect, useState } from 'react';
import { Checkbox, Dialog, useMediaQuery, useTheme } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addData, getData } from '../../utils/db';
import { getMemberList, removeMultipleMember } from '../../store/slice/memberSlice';
import { clearOldChat } from '../../store/slice/chatSlice';
import Memberdetails from './Memberdetails';
import Managemember from './Managemember';
import { CrossIcon, FilterIcon, Listmenu, Search, Deleteicon, CloseIcon, Usersetting, Deleteicons, Messages, OpenEye, Leftarrow, Rightlongarrow } from '../../Assest/Allsvg';
import Header from '../Header/Header';
import Removemember from '../Removemember/Removemember';
const Members = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [start, setStart] = useState(1)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [selcetMembers, setSelcetMember] = useState([])
  const [isAllSelect, setIsAllSelect] = useState(false)
  const [Id, setId] = useState(null)
  const [memberrole, setMemberRole] = useState('')
  const clubId = useSelector((state) => state.dashboard.club_id)
  const totalNumber = useSelector((state) => state.member.total)
  const memberlist = useSelector((state) => state.member.memberList)
  const totalPages = Math.ceil(totalNumber / limit)
  useEffect(() => {
    setSelcetMember([])
    setIsAllSelect(false)
    let clubid = clubId ? clubId : getData()?.club_id
    dispatch(getMemberList({ club_id: clubid, start, limit, search }))
  }, [dispatch, start, limit, search, clubId])
  const [currentPage, setCurrentPage] = useState(1);
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
        items.push(
          <Link to="#" key={number}
            className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 text-cloud-gray flex items-center justify-center`}
            onClick={() => handleSelectPage(number)}>
            {number}
          </Link>
        );
      } else if (number === currentPage - 2 || number === currentPage + 2) {
        if (items[items.length - 1].key !== '...') {
          items.push(
            <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
          );
        }
      }
    }
    return items;
  };
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setStart(start + 1)
    }
  };
  const handlePriviousClick = () => {
    if (currentPage > 1) {
      setStart(start - 1)
    }
  };
  const handleSelectPage = (number) => {
    setStart(number)
  };
  const handleClearSearch = () => {
    setSearch('')
  }
  useEffect(() => {
    setCurrentPage(start);
  }, [start]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [selectAll, setSelectAll] = useState(false);
  // const [selectedRows, setSelectedRows] = useState([]);
  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    // const selectedData = checked ? user.map((data) => data._id) : [];
    // setSelectedRows(selectedData);
  };

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [Open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  // manage member modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = (e) => {
    setIsModalOpen(true);
    setId(e?._id)
    setMemberRole(e.admin_status ? 'Admin' : 'Member')
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  // delete member modal
  const [Deletemember, setIsDeletemember] = useState(false);
  const handleDeleteMember = (e) => {
    setIsDeletemember(true);
    setId(e)
  };
  const handleCloseMember = () => {
    setIsDeletemember(false);
  };

  const handleViewMember = (e) => {
    addData(null, null, null, e)
    navigate('/member/viewmember');
  }

  const handleSelctForChat = (e) => {
    let conversionId
    let toId = e._id
    let fromId = getData()?.user?._id
    if (fromId < toId) {
      conversionId = fromId + toId
    } else {
      conversionId = toId + fromId
    }
    dispatch(clearOldChat())
    addData(null, null, null, null, { toId, fromId, conversionId, name: e.name, profile_img: e.profile_img, isOnline: e.isOnline })
    navigate('/member/messages')
  }

  const selectAllMember = () => {
    if (selcetMembers?.length === memberlist?.length) {
      setSelcetMember([])
      setIsAllSelect(false)
    } else {
      setSelcetMember(memberlist?.map((x) => x.user_id._id))
      setIsAllSelect(true)
    }
  }
  const selectSingle = (id) => {
    if (selcetMembers.includes(id)) {
      setSelcetMember(selcetMembers.filter((x) => x !== id))
    } else {
      setSelcetMember([...selcetMembers, id]);
    }
  }

  const handleDeleteMultipleMember = () => {
    let clubid = clubId ? clubId : getData()?.club_id
    dispatch(removeMultipleMember({ club_id: clubid, user_id: selcetMembers }))
      .unwrap()
      .then((res) => {
        if (res.success) {
          handleClickClose()
          dispatch(getMemberList({ club_id: clubid }))
          setSelcetMember([])
          setIsAllSelect(false)
        }
      })
  }
  return (
    <>
      <Header title="User Management" />
      <div className='relative h-[calc(100%_-_81px)] md:h-[calc(100%_-_97px)]'>
        <div className='p-0 md:p-6 flex flex-col h-full'>
          <div className='items-center justify-between pb-5 hidden md:flex'>
            <h4 className='text-xl text-dark-black font-bold'>| Members List</h4>
            <div className='flex items-center gap-2'>
              <Link to="/member/memberdetails" className={`border border-moon-gray bg-white p-[10px] w-11 h-11 flex items-center justify-center rounded-lg 
            ${location.pathname === '/user/userlistdetails'}`}><CrossIcon className="w-5 h-5" /></Link>
              <Link to="/member" className={`p-[10px] rounded-lg w-11 h-11 flex items-center justify-center ${location.pathname === '/member' ? 'bg-camo-green listmenuicon' : 'hello'}`}><Listmenu className="w-5 h-5" />
              </Link>
              <Link to="/member/addmember" className="border border-moon-gray bg-camo-green text-white min-w-[200px] flex items-center justify-center font-semibold p-[10px] rounded-lg">+ Add Member</Link>
            </div>
          </div>
          <div className="overflow-x-auto md:bg-white p-0 md:p-5 rounded-lg flex flex-col flex-1">
            <div className='pb-5 md:flex hidden items-center justify-between text-lg'>
              <div className='relative flex '>
                <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                  <Search className="w-4" />
                </div>
                <input type="text" placeholder='Search name, created by...' className='border border-moon-gray rounded-lg text-base font-medium md:min-w-[350px] 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray' value={search} onChange={(e) => setSearch(e.target.value)} />
                {search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1'>
                  <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                </div>}
              </div>
              <div className='flex items-center gap-2'>
                {
                  selcetMembers?.length == 0 ?
                    <div className='relative'>
                      <Link href="#" onClick={handleClick} className='text-dark-black border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium'> <FilterIcon className="fill-[red] w-5 h-5" />Filters</Link>


                      {/* <Filtermodal anchorEl={anchorEl} handleClose={handleClose} /> */}
                    </div> :
                    <div>
                      <React.Fragment>
                        <button className='text-red border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium bg-amour-gray' onClick={handleClickOpen}><Deleteicon /> {selcetMembers?.length == 1 ? 'Delete' : 'Delete All'}</button>
                        <Dialog
                          className="deletemodal"
                          fullScreen={fullScreen}
                          open={Open}
                          onClose={handleClickClose}
                          aria-labelledby="responsive-dialog-title"
                        >
                          <button onClick={handleClickClose} className='absolute hidden right-5  md:block'><CloseIcon /></button>
                          <h3 className='text-lg font-bold text-woodsmoke text-center pb-[6px] md:pb-6 md:text-[26px] md:font-semibold'>{selcetMembers?.length == 1 ? ' Delete User' : 'Delete Users'}</h3>
                          <h5 className='text-cloud-gray text-xs text-center font-semibold leading-6 md:text-lg'>Are you sure you want to Delete {selcetMembers.length == 1 ? 'this user' : 'all users'} ?</h5>
                          <div className='flex items-center justify-center gap-5 pt-[18px] md:pt-10'>
                            <button className='bg-amour-gray text-camo-green text-sm font-semibold min-w-[143px] py-3 px-10 rounded-lg md:text-lg md:px-12 md:py-4 md:min-w-[177px]' autoFocus onClick={handleClickClose} >
                              Cancel
                            </button>
                            <button autoFocus className='bg-red-orange text-white text-sm  font-semibold min-w-[143px] py-3 
                              rounded-lg md:py-4 md:text-lg md:min-w-[177px]' onClick={handleDeleteMultipleMember}>
                              Delete
                            </button>
                          </div>
                        </Dialog>
                      </React.Fragment>
                    </div>
                }
              </div>
            </div>
            <div className='overflow-auto hidden md:block flex-1'>
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="p-4 bg-white-lilac rounded-tl-lg rounded-l-lg text-start text-sm t-semibold leading-[normal] 
                  whitespace-nowrap" >
                      <Checkbox checked={isAllSelect} onChange={selectAllMember} className='!text-black/60 !p-0 select all checkbox' />
                    </th>
                    <th className="p-4 bg-white-lilac text-camo-green text-start xl:text-lg font-semibold leading-[normal] whitespace-nowrap">Members Name</th>
                    <th className="p-4 bg-white-lilac text-camo-green text-start xl:text-lg  font-semibold leading-[normal] whitespace-nowrap">Members ID</th>
                    <th className="p-4 bg-white-lilac text-camo-green text-start xl:text-lg  font-semibold leading-[normal] whitespace-nowrap">Member Role</th>
                    <th className="p-4 bg-white-lilac text-camo-green w-[210px] rounded-tr-lg ro xl:text-lg  font-semibold leading-[normal] whitespace-nowrap text-start">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memberlist?.length > 0 ?
                    memberlist?.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-4 py-3 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap">
                            <Checkbox className='!text-black/60 !p-0' checked={selcetMembers?.some(item => item === data?.user_id?._id)} onChange={() => selectSingle(data?.user_id?._id)} />
                          </td>
                          <td className="px-4 py-3 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap
                          ">
                            <div className='flex items-center gap-2'>
                              <img className='w-9 h-9 rounded-full object-cover' src={data?.user_id?.profile_img ? data?.user_id?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/gwkKVE5715Im7ymb.jpg"} alt="member-image" />
                              <h5 className='text-[#363636] font-semibold lg:text-base text-ellipsis overflow-hidden whitespace-nowrap w-[130px]' >{data?.user_id?.name}</h5>
                            </div>
                          </td>
                          <td className="px-4 py-3 text-sm text-dark-black font-semibold border-b border-b-amour-gray whitespace-nowrap
                          ">
                            @{data?._id}</td>
                          <td className="px-4 py-3 text-sm border-b border-b-amour-gray whitespace-nowrap">
                            {data?.admin_status ? <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green font-semibold'>Admin</button> : <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px]  font-semibold'>Member</button>}
                          </td>
                          <td className="px-4 py-3 text-sm text-center border-b border-b-amour-gray">
                            <div className='flex items-center gap-[10px]'>
                              <button className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white !p-0 !min-w-max' onClick={() => handleSelctForChat(data?.user_id)}>
                                <Messages className='m-auto w-[18px] fill-[#363636]' />
                              </button>
                              <button onClick={() => handleViewMember(data?._id)} className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white !p-0 !min-w-max'>
                                <OpenEye className='m-auto w-[18px]' />
                              </button>
                              <button onClick={() => handleOpenModal(data)} className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white !p-0 !min-w-max'>
                                <Usersetting className='m-auto w-[18px]' />
                              </button>
                              <button onClick={() => handleDeleteMember(data?.user_id?._id)} className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white !p-0 !min-w-max'>
                                <Deleteicons className='m-auto w-[18px]' />
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })
                    : <tr>
                      <td colSpan='5' className="text-center pt-3 text-[#363636] font-semibold">Data not found!</td>
                    </tr>
                  }
                </tbody>
              </table>
              <Managemember open={isModalOpen} handleClose={handleCloseModal} id={Id} role={memberrole} />
              <Removemember open={Deletemember} handleClose={handleCloseMember} id={Id} />
            </div>
            <div className='block md:hidden h-full'>
              <Memberdetails />
            </div>
            <div className='hidden items-center justify-between pt-4 w-full md:flex'>
              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>
              <ul className='flex items-center'>
                {paginationItems()}
              </ul>
              <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>
            </div>
          </div>
        </div>
        <a className="w-[50px] h-[50px] bg-camo-green rounded-full flex md:hidden items-center justify-center absolute bottom-20 end-5 addbtnshadow" href="/member/addmember">
          <svg width="17" height="17" className="w-5 h-5" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 17C7.7669 17 7.54335 16.9074 7.37852 16.7426C7.21369 16.5777 7.12109 16.3542 7.12109 16.1211V-0.121094C7.12109 -0.354194 7.21369 -0.577747 7.37852 -0.742574C7.54335 -0.907401 7.7669 -1 8 -1C8.2331 -1 8.45665 -0.907401 8.62148 -0.742574C8.78631 -0.577747 8.87891 -0.354194 8.87891 -0.121094V16.1211C8.87891 16.3542 8.78631 16.5777 8.62148 16.7426C8.45665 16.9074 8.2331 17 8 17Z" fill="white"></path><path d="M16.1211 8.87891H-0.121094C-0.354194 8.87891 -0.577747 8.78631 -0.742574 8.62148C-0.907401 8.45665 -1 8.2331 -1 8C-1 7.7669 -0.907401 7.54335 -0.742574 7.37852C-0.577747 7.21369 -0.354194 7.12109 -0.121094 7.12109H16.1211C16.3542 7.12109 16.5777 7.21369 16.7426 7.37852C16.9074 7.54335 17 7.7669 17 8C17 8.2331 16.9074 8.45665 16.7426 8.62148C16.5777 8.78631 16.3542 8.87891 16.1211 8.87891Z" fill="white"></path>
          </svg>
        </a>
      </div>
    </>
  )
}
export default Members;