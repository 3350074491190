import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getData } from '../../utils/db';
import { getRecentActivity } from '../../store/slice/dashboardSlice';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Leftarrow, Rightlongarrow } from '../../Assest/Allsvg';
import Header from '../Header/Header'; 

const Recentactivity = () => {
  const activites = useSelector((state) => state.dashboard.activites);
  const navigate = useNavigate();
  const clubId = useSelector((state) => state.dashboard.club_id);
  const dispatch = useDispatch();
  const [page, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const totalNumber = useSelector((state) => state.dashboard.activitesCount);
  useEffect(() => {
    let data = getData();
    let club_id = clubId ? clubId : data?.club_id
    if (club_id) {
      dispatch(getRecentActivity({ club_id, page, limit }))
    }
  }, [dispatch, page, limit, clubId]);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalNumber / limit)
  const paginationItems = () => {
    let items = [];
    for (let number = 1; number <= totalPages; number++) {
      if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
        items.push(
          <Link to="#" key={number}
            className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 text-cloud-gray flex items-center justify-center`}
            onClick={() => handleSelectPage(number)}>
            {number}
          </Link>
        );
      } else if (number === currentPage - 2 || number === currentPage + 2) {
        if (items[items.length - 1].key !== '...') {
          items.push(
            <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
          );
        }
      }
    }
    return items;
  };
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setStart(page + 1)
    }
  };
  const handlePriviousClick = () => {
    if (currentPage > 1) {
      setStart(page - 1)
    }
  };
  const handleSelectPage = (number) => {
    setStart(number)
  };
  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handleBackDashboard = () => {
    navigate('/dashboard')
  }
  // const handleOpenRecentActivity = () => {
  //   navigate('/dashboard/recentactivity')
  // }
  return (
    <div className='flex flex-col h-full' >
      <Header title="Dashboard" />
      <div className='p-4 sm:p-6 flex-1'>
        <div className="bg-white  flex-col p-5 h-full rounded-lg flex">
          <h4 className='text-dark-black text-sm font-bold md:text-xl flex items-center pb-3 gap-2'>
            <button onClick={handleBackDashboard}>
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6" width="28" height="28" viewBox="0 0 28 28" fill="none"><path d="M11.1652 6.91797L4.0835 13.9996L11.1652 21.0813" stroke="#323232" strokeWidth="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M23.9167 14H4.28174" stroke="#323232" strokeWidth="3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg>
            </button> | Recent Activity</h4>
          <div className='overflow-auto flex-1 hidden md:block'>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="bg-white-lilac text-[#363636] text-start text-base font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tl-lg rounded-l-lg leading-normal">Member</th>
                  <th className="bg-white-lilac text-[#363636] text-start text-base font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Activity</th>
                  <th className="bg-white-lilac text-[#363636] text-start text-base font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap leading-normal">Create Date/Time</th>
                  {/* <th className="bg-white-lilac text-camo-green text-sm text-center font-semibold py-2 px-3 md:px-4 md:py-3 whitespace-nowrap rounded-tr-lg rounded-br-lg leading-normal">Action</th> */}
                </tr>
              </thead>
              {activites?.length > 0 ? activites.map((data, index) => (
                <tr key={index}>
                  <td className="py-2 px-3 md:px-4 md:py-3 flex gap-[10px] items-center">
                    <img src={data?.user?.profile_img ? data?.user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt='' className='rounded-full h-7 w-7 flex-shrink-0'></img>
                    <h6 className='text-sm font-semibold capitalize text-[#363636] text-ellipsis overflow-hidden whitespace-nowrap w-[80px]'>{data?.user?.name}</h6>
                  </td>
                  <td className="py-2 px-3 md:px-4 md:py-3 whitespace-nowrap">
                    <span className={`border rounded-md py-[6px] px-4 text-sm font-medium capitalize ${data.type === 'Check-Out' ? ' border-[#EB5757] bg-[#EB5757]/10 text-[#EB5757]' : data?.type === 'Check-In' ? 'border-[#219653] bg-[#219653]/10 text-[#219653]' : data?.type === "Club notify" ? 'border-[#3890EE] bg-[#3890EE]/10 text-[#3890EE]' : data?.type === "Add Club Post" ? 'border-[#F8258A] bg-[#F8258A]/10 text-[#F8258A]' : 'border-[#EF7632] bg-[#EF7632]/10 text-[#EF7632]'}`} >{data.type === 'Add Club Post' ? 'Added Post' : data?.type === 'Add Member' ? 'Added Member' : data.type}</span>
                  </td>
                  <td className="py-2 px-3 md:px-4 md:py-3 whitespace-nowrap">
                    <span className='text-[#686868] font-semibold text-sm' >{moment(data.createdAt).format('DD MMM, YYYY hh:mm A')}</span>
                  </td>
                  {/* <td className="py-2 px-3 md:px-4 md:py-3 whitespace-nowrap text-center">
                      <Link href="#" ><OpenEye className='m-auto w-5' /></Link>
                    </td> */}
                </tr>
              )) : (
                <tr>
                  <td colSpan="5" className="text-center py-4 font-medium text-[#363636]">No activites available</td>
                </tr>
              )}
            </table>
          </div>
          <div className='hidden items-center justify-between pt-4 w-full md:flex'>
            <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>
            <ul className='flex items-center'>
              {paginationItems()}
            </ul>
            <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>
          </div>
          <div className='block bg-white rounded-[10px] md:hidden'>
            <div className='flex flex-col text-lg pb-3 sm:pb-5'>
              {activites?.length > 0 ? activites.map((data, index) => (
                <div key={index} className='border-b md:border border-amour-gray pb-4 sm:pb-5 md:p-4 sm:mb-5 md:mb-0 gap-3 sm:gap-4 md:gap-6 flex justify-center flex-col usergridlist mb-4'>
                  <div className='flex justify-between items-center gap-2 text-dark-black font-semibold text-sm md:text-base'>
                    <div className='flex items-center gap-3'>
                      <img src={data?.action_user?.profile_img ? data?.action_user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt='' className='rounded-full  h-7 w-7 flex-shrink-0'></img>
                      <h6 className='text-[#363636] font-semibold text-sm text-ellipsis overflow-hidden whitespace-nowrap w-[100px]' >{data?.action_user?.name}</h6>
                    </div>
                  </div>
                  <table>
                    <tbody className='flex justify-center flex-col gap-3'>
                      <tr className='flex sm:items-center flex-wrap gap-2 md:gap-3'>
                        <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Activity<span>:</span></td>
                        <td>
                          <span className={`border rounded-md py-[6px] px-4 text-sm font-medium capitalize ${data.type === 'Check-Out' ? ' border-[#EB5757] bg-[#EB5757]/10 text-[#EB5757]' : data?.type === 'Check-In' ? 'border-[#219653] bg-[#219653]/10 text-[#219653]' : data?.type === "Club notify" ? 'border-[#3890EE] bg-[#3890EE]/10 text-[#3890EE]' : data?.type === "Add Club Post" ? 'border-[#F8258A] bg-[#F8258A]/10 text-[#F8258A]' : 'border-[#EF7632] bg-[#EF7632]/10 text-[#EF7632]'}`} >{data.type}</span>
                        </td>
                      </tr>
                      <tr className='flex sm:items-center flex-wrap gap-2 md:gap-3'>
                        <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Create Date/Time<span>:</span></td>
                        <td>
                          <span className='text-[#686868] text-sm font-semibold' >{moment(data.createdAt).format('DD MMM, YYYY hh:mm A  ')}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )) : (<div className='col-span-4'>
                <p className='text-center text-sm text-cloud-gray font-semibold'>Data not found</p>
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
export default Recentactivity;
