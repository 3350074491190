import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";


// for get messages
export const showAllMessge = createAsyncThunk(
    '/chat/showAllMessge', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('v1/chat/show_all_msg', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)




const chatSlice = createSlice({
    name: "chat",
    initialState: {
        chats: [],
        message: {},
        total: 0,
        error: ''
    },
    reducers: {
        appendMessageInChat: (state, action) => {
            state.chats.push(action.payload);
        },
        clearOldChat: (state, action) => {
            state.chats = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(showAllMessge.fulfilled, (state, action) => {
                state.chats = action.payload.data.show_all_msg
                state.total = action.payload.data.totalMsg
                state.error = action.payload.success ? ' ' : action.payload.message
            })
            .addCase(showAllMessge.rejected, (state, action) => {
                state.error = action.payload
                state.chats = []
                state.total = 0
            })
    }
})

export const { appendMessageInChat } = chatSlice.actions
export const { clearOldChat } = chatSlice.actions

export default chatSlice.reducer