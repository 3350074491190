import React from "react";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode"; // Correct named import
import { useDispatch } from "react-redux"; // Assuming you're using Redux
import { socialLogin } from "../../store/slice/adminSlice";
import { useNavigate } from "react-router-dom";

const clientId = process.env.REACT_APP_CLIENT_ID;

const GoogleLoginButton = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onSuccess = (response) => {
        const credential = response.credential;
        const decoded = jwtDecode(credential); // Decode the JWT token correctly
        console.log("Login Success:", decoded);
        let data = {
            email: decoded?.email,
            uid: decoded?.jti,
            name: decoded?.name,
            fcm_token: [],
            picture: decoded?.picture

        }
        dispatch(socialLogin(data))
            .unwrap()
            .then((res) => {
                if (res.success) {
                    navigate('/dashboard')
                }
            })
    };

    const onFailure = () => {
        console.error("Login failed");
        // Optionally handle failure here
        // dispatch({ type: "LOGIN_FAILURE" });
    };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
                text="signin_with"
                shape="rectangular"
                theme="outline"
                size="large"
                width="250px"
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;