import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getData } from "../../utils/db";

//for find all club of admins
export const allClubOfAdmin = createAsyncThunk(
    '/dashboard/allClubOfAdmin', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post("v2/club/allClubofAdmin")
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const getAllContsAndAlert = createAsyncThunk(
    '/dashboard/getAllContsAndAlert', async (data, { rejectWithValue }) => {
        try {

            const response = await axios.post('v2/dashboard/getCountsData', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const getRecentActivity = createAsyncThunk(
    '/dashboard/getRecentActivity', async (data, { rejectWithValue }) => {
        try {

            const response = await axios.post('v1/notification/show_club_notification', data)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const getCheckInUser = createAsyncThunk(
    '/dashboard/getCheckInUser', async (id, { rejectWithValue }) => {
        try {

            const response = await axios.get(`v2/dashboard/findCheckInUser/${id}`)
            return response.data
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)

export const userNotification = createAsyncThunk(
    '/dashboard/userNotification', async (data, { rejectWithValue }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}v1/notification/show_notification?read=${data?.read}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getData().token}`
                },
                // body: JSON.stringify(data)
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)
export const wether = createAsyncThunk(
    '/dashboard/wether', async (data, { rejectWithValue }) => {
        try {
            const response = await axios.post('/v1/weather/tenDayWeather', data)
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data.message)
        }
    }
)
const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        clubs: [],
        error: '',
        club_id: null,
        counts: null,
        alert: [],
        alertCount: 0,
        activitesCount: 0,
        activites: [],
        area: [],
        checkInUsers: [],
        notification: [],
        notificationCount: 0,
        location: null,
        wether: null
    },
    reducers: {
        selectClubId: (state, action) => {
            state.club_id = action.payload
        },
        setCheckInUsers: (state, action) => {
            state.checkInUsers = action.payload
        },
        setClubLocation: (state, action) => {
            state.location = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(allClubOfAdmin.fulfilled, (state, action) => {
                state.clubs = action.payload.data
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(allClubOfAdmin.rejected, (state, action) => {
                state.clubs = []
                state.error = action.payload
            })
            .addCase(getAllContsAndAlert.fulfilled, (state, action) => {
                state.alert = action.payload.data.alert
                state.alertCount = action.payload.data.totalAlert
                state.counts = {
                    activeMemeber: action.payload.data.activeMembers,
                    post: action.payload.data.post,
                    kills: action.payload.data.kills,
                    checkIn: action.payload.data.checkIn,
                    checkout: action.payload.data.checkout,
                    totalMember: action.payload.data.totalMember

                }
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(getAllContsAndAlert.rejected, (state, action) => {
                state.alert = []
                state.counts = null
                state.alertCount = 0
                state.error = action.payload
            })
            .addCase(getRecentActivity.fulfilled, (state, action) => {
                state.activites = action.payload.data.notification
                state.activitesCount = action.payload.data.total
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(getRecentActivity.rejected, (state, action) => {
                state.activites = []
                state.error = action.payload
            })
            .addCase(getCheckInUser.fulfilled, (state, action) => {
                state.checkInUsers = action.payload.data.checkIn
                state.area = action.payload.data.area
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(getCheckInUser.rejected, (state, action) => {

                state.error = action.payload
            })
            .addCase(userNotification.fulfilled, (state, action) => {
                state.notification = action.payload.data.notification
                state.notificationCount = action.payload.data.total_notification

                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(userNotification.rejected, (state, action) => {
                state.error = action.payload
            })
            .addCase(wether.fulfilled, (state, action) => {
                state.wether = action.payload.data.days[0]
                state.error = action.payload.success ? '' : action.payload.message
            })
            .addCase(wether.rejected, (state, action) => {
                state.error = action.payload
            })
    }
})

export const { selectClubId } = dashboardSlice.actions
export const { setCheckInUsers } = dashboardSlice.actions
export const { setClubLocation } = dashboardSlice.actions
export default dashboardSlice.reducer