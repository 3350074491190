import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Flashicon, Leftangle, Sendicon } from '../../Assest/Allsvg';
import Header from '../Header/Header';
import { useDispatch, useSelector } from 'react-redux';
import { getData } from '../../utils/db';
import shadows from '@mui/material/styles/shadows';
import { showAllMessge } from '../../store/slice/chatSlice';
import moment from 'moment';
import socket from '../../utils/socket';

const Messages = () => {
    const dispatch = useDispatch()
    const [isClub, setIsClub] = useState(false)
    const chats = useSelector((state) => state.chat.chats)
    const [message, setMessage] = useState('')
    const [admin, setADmin] = useState(null)
    const [user, setUser] = useState(null)
    useEffect(() => {
        let data = getData().chat
        socket.emit("createRoom", { toId: data?.toId, fromId: data?.fromId, conversionId: data?.conversionId })
        dispatch(showAllMessge({ conversionId: data?.conversionId, isClub }))
        setUser({
            name: data?.name,
            profile_img: data?.profile_img,
            isOnline: data?.isOnline
        })
        setADmin(data?.fromId)
    }, [dispatch, isClub])

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage();
        }
    };
    const handleSendMessage = () => {
        if (message) {
            let data = getData().chat
            socket.emit('sendMessage', { toId: data?.toId, fromId: data?.fromId, msgTo: 0, type: 0, message: message, firstMsg: 0 })
            setMessage('')
        }
    }
    return (
        <>
            <Header title="User Management" />
            <div className='flex flex-col flex-1 p-4 sm:py-6 sm:px-5  h-[calc(100%_-_97px)]'>
                <h4 className='md:text-xl text-dark-black font-bold flex items-center gap-2 sm:gap-3 pb-2 sm:pb-4'>
                    <Link href="#" to="/member" ><Leftangle className="w-5 sm:w-6" /></Link >| Message</h4>
                <div className='bg-white rounded-xl h-[calc(100%_-_44px)] py-4 sm:py-6 flex flex-col'>
                    <div className='border-b border-b-[#EEEEEE] pb-4 px-4 sm:px-9 flex items-center justify-between gap-3 sm:gap-4'>
                        <div className='flex items-center gap-[14px]'>
                            <img className='w-10 h-10 sm:w-[50px] sm:h-[50px] rounded-full' src={user?.profile_img ? user?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/gwkKVE5715Im7ymb.jpg"} alt="" />
                            <div className='flex flex-col' >
                                <h4 className='sm:text-lg text-[#363636] font-semibold'>{user?.name}</h4>
                                <span className='text-cloud-gray text-xs sm:text-sm'>{user && user?.isOnline == 1 ? 'Online' : 'Offline'}</span>
                            </div>
                        </div>
                        <Link to="#">
                            <svg xmlns="http://www.w3.org/2000/svg" className="m-auto w-5" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M14 5C14 3.9 13.1 3 12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5Z" fill="#363636" stroke="#363636" strokeWidth="0.5"></path><path d="M14 19C14 17.9 13.1 17 12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19Z" fill="#363636" stroke="#363636" strokeWidth="0.5"></path><path d="M14 12C14 10.9 13.1 10 12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12Z" fill="#363636" stroke="#363636" strokeWidth="0.5"></path></svg>
                        </Link>
                    </div>
                    <div className='flex-1 flex flex-col-reverse items-end p-4 sm:px-8 sm:py-9 overflow-y-auto h-[calc(100%_-_140px)]'>
                        <div className='w-full'>
                            <div className='flex flex-col gap-5'>
                                {chats?.length > 0 ? (
                                    chats.map((data, index) => (
                                        (data?.receiverdat?._id?.toString() || data?.receiverId) === admin?.toString() ? (
                                            <div key={index} className='flex flex-col items-start gap-2'>
                                                <span className=' bg-light-green-gray capitalize font-medium px-4 py-3 leading-[normal] rounded-tl-lg text-sm sm:text-base sm:rounded-tl-xl sm:rounded-bl-xl sm:rounded-tr-xl'>
                                                    {data?.type == 4 ?
                                                        <img src={data?.post?.photos[0]}></img> : data.message || 'Hey!!! Good Morning'}
                                                </span>
                                                <h6 className='text-cloud-gray text-xs'>
                                                    {moment(data.createdAt).format("LT")}
                                                </h6>
                                            </div>
                                        ) : (
                                            <div key={index} className="flex flex-col items-end gap-2 justify-end">
                                                <span className='bg-camo-green text-white capitalize text-end font-medium px-4 py-3 leading-[normal] rounded-tl-lg text-sm rounded-bl-lg rounded-tr-lg sm:rounded-tl-xl sm:rounded-bl-xl sm:rounded-tr-xl'>
                                                    {data.message || 'Hey!!! Good Morning'}
                                                </span>
                                                    <h6 className='text-cloud-gray text-xs text-end'>
                                                        {moment(data.createdAt).format("LT")}
                                                    </h6>
                                            </div>
                                        )
                                    ))
                                ) : (
                                    <div className='flex flex-col justify-center text-center'>
                                        <h6 className='text-cloud-gray order-2 sm:order-1 font-medium text-sm sm:text-base '>
                                            No chat found
                                        </h6>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='mx-4 sm:mx-8 relative min-h-14 sm:min-h-[60px]'>
                        <button className='absolute top-1/2 -translate-y-1/2 left-5 border-r-2 border-r-[#68686833] pr-3 sm:pr-5 py-[4px] sm:py-[6px]'><Flashicon /></button>
                        <input className='w-full bg-light-green-gray ps-[70px] sm:ps-[90px] pr-3 min-h-[inherit] rounded-full border 
                        border-pele-light-gray' type="text" placeholder='Type your message...' value={message} onChange={(e) => setMessage(e.target.value)} onKeyDown={handleKeyPress} />
                        <button className='bg-camo-green h-9 w-9 sm:h-11 sm:w-11  absolute top-1/2 -translate-y-1/2 right-3 rounded-full flex items-center justify-center' onClick={handleSendMessage} ><Sendicon className="w-[18px] sm:w-6" /></button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Messages;
