import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Members from '../Components/Members/Members';
import Memberdetails from '../Components/Members/Memberdetails';
import Sidebar from '../Components/Sidebar/Sidebar';
import Addmember from '../Components/Members/Addmember';
import Viewmember from '../Components/Members/Viewmember';
import Messages from '../Components/Members/Messages';

const Memberpage = () => {
  return (
    <>
      <div className='bg-light-gray h-screen'>
        <Sidebar />
        <div className='w-full lg:w-[calc(100%_-_300px)] xl:w-[calc(100%_-_350px)] ml-auto h-full overflow-auto flex flex-col'>
          <Routes>
            <Route path="/*" element={<Members />} />
            <Route path="/addmember" element={<Addmember />} />
            <Route path="/memberdetails" element={<Memberdetails />} />
            <Route path="/viewmember" element={<Viewmember />} />
            <Route path="/messages" element={<Messages />} />
          </Routes>
        </div>
      </div>
    </>
  )
}
export default Memberpage;