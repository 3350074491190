import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select } from '@mui/material';
import { addData, getData } from '../../utils/db';
import { allClubOfAdmin, selectClubId, setClubLocation, userNotification, wether } from '../../store/slice/dashboardSlice';
import { Downarrow, Huntlogo, NotificationActive, NotificationUnActive, Togglemenu, Uparrow } from '../../Assest/Allsvg';
import SidebarDrawer from './SidebarDrawer';
import Notificationpopup from '../Notificationpopup/Notificationpopup';

const Header = () => {
    const dispatch = useDispatch()
    const [club_id, setClub_id] = useState('');
    const [Admin, setAdmin] = useState(null)
    const admin = useSelector((state) => state.admin.admin);
    const clubs = useSelector((state) => state.dashboard.clubs)
    const notification = useSelector((state) => state.dashboard.notification)
    const clubLocation = useSelector((state) => state.dashboard.location)
    const unreadCount = notification[0]?.notification?.filter(notification => !notification.read).length;
    useEffect(() => {
        dispatch(allClubOfAdmin());
    }, [dispatch]);
    useEffect(() => {
        let data = getData()
        if (admin) {
            setAdmin(admin)
        } else {
            setAdmin(data?.user)
        }
        if (data && data?.club_id) {
            setClub_id(data.club_id);
            dispatch(setClubLocation(data?.location))
        } else {
            if (clubs?.length > 0) {
                setClub_id(clubs[0]?._id?.toString())
                dispatch(selectClubId(clubs[0]?._id))
                dispatch(setClubLocation(clubs[0]?.location));
                addData(null, null, null, null, null, clubs[0]?.location);
            }
        }

    }, [clubs, admin, dispatch])
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };
    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        dispatch(userNotification({ page: 1, limit: 5, read: 1 }))
    };
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };
    const handleSelcetClub = (e) => {
        setClub_id(e.target.value)
        dispatch(selectClubId(e.target.value))
        const location = clubs.filter((x) => x?._id === e.target.value)
        dispatch(setClubLocation(location[0]?.location))
        addData(null, null, e.target.value, null, null, location[0]?.location)
    }
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    return (
        <div className='flex items-center justify-between border-b border-b-light-gray bg-white p-4 md:p-5'>
            <div className='flex items-center gap-4'>
                <div className='flex md:hidden gap-3'>
                    <button onClick={handleDrawerOpen}><Togglemenu /></button>
                    <SidebarDrawer isDrawerOpen={isDrawerOpen} handleDrawerClose={handleDrawerClose} />
                    <Huntlogo />
                </div>
                <div className='hidden md:block' >
                    <h3 className='text-dark-black text-base font-bold md:text-xl xl:text-2xl'>Dashboard</h3>
                    {/* <span className='block'>Welcome {Admin?.username}</span> */}
                </div>
            </div>
            <div className='flex items-center gap-4 sm:gap-5'>
                <Select
                    className='club-select'
                    value={club_id}
                    onChange={handleSelcetClub}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    IconComponent={open ? Uparrow : Downarrow}
                    onOpen={handleOpen}
                    onClose={handleClose}
                >
                    {clubs?.length > 0
                        ? clubs
                            .map((data, index) => (
                                <MenuItem key={index} value={data?._id}>
                                    <div className='flex items-center gap-3 w-full'>
                                        <img
                                            className='w-7 h-7 rounded-full'
                                            src={data?.club_profile ? data?.club_profile : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/gwkKVE5715Im7ymb.jpg"}
                                            alt=""
                                        />
                                        <h4 className='text-sm text-[#363636] font-medium text-ellipsis md:text-unset overflow-hidden whitespace-nowrap w-[70px] sm:w-[140px]'>
                                            {data?.name}
                                        </h4>
                                    </div>
                                </MenuItem>
                            ))
                        : <MenuItem value="">No users found</MenuItem>}
                </Select>
                <div className='flex items-center gap-2 md:ms-auto'>
                    <img src={Admin?.profile_img} alt="" className='w-8 h-8 rounded-full' />
                    <span className='hidden text-black-color font-semibold md:flex'>{Admin?.name}</span>
                </div>
                <div>
                    <Link to="#" onClick={handleClick}>
                        {unreadCount > 0 ? <NotificationActive /> : <NotificationUnActive />}
                    </Link>
                    <Notificationpopup anchorEl={anchorEl} handleClose={handleClose} className="notification-popup" />
                </div>
            </div>
        </div>
    )
}
export default Header;