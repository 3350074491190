import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Category, Clubmanage, Communications, Logoimage, Logout, Mapview, Membericon, Sefty} from '../../Assest/Allsvg';
import { logOut } from '../../utils/db';

const Sidebar = () => {
    const location = useLocation();
    let navigate = useNavigate();
    const handleLogut = async (event) => {
        event.preventDefault()
        logOut()
        navigate('/');
    }
    const MembersRoutes = [
        '/member',
        '/member/addmember',
        '/member/memberdetails',
    ];
    const isActiveMembers = MembersRoutes.some(route => location.pathname.startsWith(route));
    return (
        <div className="sidebar_main pt-6 pb-2 fixed h-full min-w-[300px]  xl:min-w-[350px] box-border md:bg-white hidden lg:flex flex-col justify-between overflow-auto">
            <div>
                <Link to="/dashboard" className='px-5 block'>
                    <Logoimage className="w-[200px] xl:w-[250px] mx-auto" />
                </Link>
                <ul className="sidebar_menu pt-6 pr-[10px]">
                    <li><Link to="/dashboard" className={`flex items-center font-semibold gap-2 py-3 xl:py-4 px-5 text-sm md:text-base 
                     ${location.pathname === '/dashboard' ? 'rounded-lg md:border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold' : 'border-l-4 border-transparent font-medium text-cloud-gray'}`}>
                        <Category className="w-5" /> Dashboard</Link></li>
                    <li>
                        <Link
                            to="/member"
                            className={`flex items-center font-semibold gap-2 py-3 xl:py-4 px-5 text-sm md:text-base ${isActiveMembers
                                ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white text-camo-green font-semibold'
                                : 'border-l-4 border-transparent text-cloud-gray'
                                }`}
                        >
                            <Membericon className="w-5" />Members
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="#"
                            className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base'
                        >
                            <Mapview className="w-5" />Map Management
                        </Link>
                    </li>
                    <li>
                        <Link to="#" className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base'><Communications className="w-5" />Communications</Link>
                    </li>
                    <li>
                        <Link
                            to="#"
                            className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base '
                        >
                            <Sefty className="w-5" />Safety and Alerts
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="#"
                            className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base'
                        >
                            <Clubmanage className="w-5" />Club Management
                        </Link>
                    </li>
                </ul>
            </div>
            <ul>
                <li><Link onClick={handleLogut} className={`flex items-center text-cloud-gray font-medium gap-2 py-4 px-5 text-sm md:text-base  ${location.pathname === '/' ? 'rounded-lg border-l-4 border-[#47543C] bg-milk-white font-semibold' : 'border-l-4 border-transparent'
                    }`} > <Logout className="w-5" />Log Out</Link>
                </li>
            </ul>
        </div>
    )
}
export default Sidebar;