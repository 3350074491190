import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Select, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { joinClub, userForAddMember } from '../../store/slice/memberSlice';
import { getData } from '../../utils/db';
import Successmodal from '../Successmodal/Successmodal';
import { Leftangle, Search } from '../../Assest/Allsvg';
import Header from '../Header/Header';

const Addmember = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [user_id, setUser_id] = useState("");
    const [search, setSearch] = useState("");
    const [displayedUsers, setDisplayedUsers] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const users = useSelector((state) => state.member.users);
    const error = useSelector((state) => state.member.error);
    const clubId = useSelector((state) => state.dashboard.club_id);
    const [err, setError] = useState(null);
    useEffect(() => {
        const id = clubId ? clubId : getData()?.club_id;
        dispatch(userForAddMember({ club_id: id, page, limit, search }));
    }, [dispatch, clubId, page, limit, search]);

    useEffect(() => {
        if (page === 1) {
            setDisplayedUsers(users);
        } else {
            setDisplayedUsers((prevUsers) => [...prevUsers, ...users]);
        }
        if (users.length < limit) {
            setHasMore(false);
        }
    }, [users]);
    const handleChange = (event) => {
        setUser_id(event.target.value);
    };
    const handleSearch = (event) => {
        setSearch(event.target.value);
        setPage(1); // Reset to first page on search
        setDisplayedUsers([]);
        setHasMore(true);
    };
    const handleJoinClub = () => {
        if (!user_id) {
            return setError("User is required for add to club member.");
        }
        setError(null);
        const id = clubId ? clubId : getData()?.club_id;
        dispatch(joinClub({ club_id: id, user_id: [user_id] }))
            .unwrap()
            .then((res) => {
                if (res.success) {
                    setOpenSuccess(true);
                    navigate('/member');
                }
            });
    };
    const [OpenSuccess, setOpenSuccess] = useState(false);
    const handleCloseSuccess = () => {
        setOpenSuccess(false);
    };
    const handleScroll = (event) => {
        const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
        if (bottom) {
            setLimit(limit + 10)
        }
        const top = event.target.scrollTop === 0;
        if (top && limit > 15) {
            setLimit(limit - 10)
        }
    };
    const clearSearch = () => {
        setSearch('');
    };
    return (
        <>
            <Header title="User Management" />
            <div className='flex flex-col p-4 sm:px-5 sm:py-6 h-[calc(100%_-_65px)] md:h-[calc(100%_-_85px)]'>
                <div className='flex flex-col flex-1 overflow-auto bg-white py-5 rounded-[10px] md:py-0 md:bg-transparent'>
                    <h4 className='flex items-center justify-start text-dark-black text-sm font-bold pb-5 px-5 gap-3 md:text-xl md:px-0'>
                        <Link to="/member" className="hidden md:block"><Leftangle className="w-6" /></Link> | Add Member
                    </h4>
                    <div className='flex flex-col justify-between overflow-auto h-full px-5 rounded-[10px] gap-10 md:bg-white md:p-8'>
                        <div>
                            <h5 className='text-[#363636] font-semibold sm:text-lg'>Add new member to the club</h5>
                            <div>
                                <Select
                                    className='member-select'
                                    value={user_id}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    MenuProps={{
                                        PaperProps: {
                                            onScroll: handleScroll,
                                            style: { maxHeight: 300, overflow: 'auto' }
                                        }
                                    }}
                                >
                                    <div className='relative min-h-10 sm:min-h-12 my-2 mx-4'>
                                        <button className='absolute top-1/2 left-4 -translate-y-1/2'>
                                            <Search className="w-5 h-5" />
                                        </button>
                                        <input
                                            className='border border-[#6868684d] w-full text-sm font-medium sm:text-base min-h-[inherit] px-11 rounded-lg text-[#686868] placeholder:text-[#686868] placeholder:font-medium'
                                            type="text"
                                            placeholder='Search member'
                                            value={search}
                                            onChange={handleSearch}
                                        />
                                        {search && (
                                            <button onClick={clearSearch} className='absolute top-1/2 right-4 -translate-y-1/2'>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                                    <path d="M19.6815 6.08691L14 11.7685L8.31839 6.08691L6.08691 8.31839L11.7685 14L6.08691 19.6815L8.31839 21.913L14 16.2314L19.6815 21.913L21.913 19.6815L16.2314 14L21.913 8.31839L19.6815 6.08691Z" fill="#686868"></path>
                                                </svg>
                                            </button>
                                        )}
                                    </div>
                                    <MenuItem value="" className='font-sans font-bold heylloo'>Select member</MenuItem>
                                    {
                                        users?.length > 0 ?
                                            users.map((data, index) => {
                                                return (
                                                    <MenuItem value={data?._id} key={index}>
                                                        <div className='flex items-center gap-3 w-full'>
                                                            <img className='w-9 h-9 rounded-full' src={data?.profile_img ? data?.profile_img : "https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/xRxdOvd49y8MtPQP.jpg"} alt="" />
                                                            <div>
                                                                <h4 className='text-sm text-[#363636] font-semibold'>{data?.name}</h4>
                                                                <span className='text-[cloud-gray] text-xs'>@{data?.username}</span>
                                                            </div>
                                                        </div>
                                                    </MenuItem>
                                                )
                                            })
                                            :
                                            <MenuItem value="" disabled>No users found</MenuItem>
                                    }
                                </Select>
                            </div>
                            <span className='text-red pt-3 inline-block font-medium'>{err || error}</span>
                        </div>
                        <div className='grid grid-cols-2  items-center justify-center text-center pt-5 gap-4 md:flex md:pt-0 md:gap-7'>
                            <Link to="/member" className='border border-camo-green text-camo-green 
                            box-border text-sm font-semibold py-3 px-12 rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[180px]'>Cancel</Link>
                            <Link onClick={handleJoinClub} to="#" className='border border-transparent bg-camo-green text-white 
                            text-sm font-semibold box-border py-3 px-12 rounded-lg min-w-[50%] leading-[100%] md:text-base md:min-w-[180px]' >Add</Link>
                            <Successmodal open={OpenSuccess} handleClose={handleCloseSuccess} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Addmember;
