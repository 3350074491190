import socket from "./socket";

export const addData = (token, user, club_id, memberId, chat, location) => {

    let existingData = localStorage.getItem("huntingClub");

    // Parse the existing data if it exists, otherwise initialize an empty object
    existingData = existingData ? JSON.parse(existingData) : {};

    // Update the token and user if provided, otherwise retain the existing values
    if (token) {
        existingData.token = token;
    }
    if (club_id) {
        existingData.club_id = club_id
    }
    if (user) {
        existingData.user = user;
    }
    if (memberId) {
        existingData.memberId = memberId;
    }
    if (chat) {
        existingData.chat = chat;
    }
    if (location) {
        existingData.location = location;
    }

    // Store the updated data back in local storage
    localStorage.setItem("huntingClub", JSON.stringify(existingData));
}

export const getData = (token, user) => {
    let existingData = localStorage.getItem("huntingClub")
    existingData = existingData ? JSON.parse(existingData) : null

    return existingData

}

export const logOut = () => {
    return new Promise((resolve) => {
        // Get existing data from localStorage and parse it into an object
        let existingData = JSON.parse(localStorage.getItem("huntingClub")) || {};

        // Delete specified properties from the existingData object
        delete existingData.token;
        delete existingData.club_id;
        delete existingData.memberId;
        delete existingData.chat;
        delete existingData.location;

        // Save the modified object back to localStorage
        localStorage.setItem("huntingClub", JSON.stringify(existingData));

        // Emit logout event through socket and disconnect the socket
        if (socket) {
            socket.emit("logOutSocket");
            socket.disconnect();
        }

        // Resolve the promise to signal completion
        resolve(true);
    });
};
