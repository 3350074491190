import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMemberList } from '../../store/slice/memberSlice';
import { addData, getData } from '../../utils/db';
import { clearOldChat } from '../../store/slice/chatSlice';
import { CloseIcon, CrossIcon, Deleteicons, FilterIcon, Leftarrow, Listmenu, Messages, OpenEye, Rightlongarrow, Search, Usersetting } from '../../Assest/Allsvg';
import Managemember from './Managemember';
import Removemember from '../Removemember/Removemember';
import Header from '../Header/Header';

const Memberdetails = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [start, setStart] = useState(1)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [Id, setId] = useState(null)
    const clubId = useSelector((state) => state.dashboard.club_id)
    const totalNumber = useSelector((state) => state.member.total)
    const memberlist = useSelector((state) => state.member.memberList)
    const totalPages = Math.ceil(totalNumber / limit)
    useEffect(() => {
        let clubid = clubId ? clubId : getData()?.club_id
        dispatch(getMemberList({ club_id: clubid, start, limit, search }))
    }, [dispatch, start, limit, search, clubId])
    const [currentPage, setCurrentPage] = useState(1);
    const paginationItems = () => {
        let items = [];
        for (let number = 1; number <= totalPages; number++) {
            if (number === 1 || number === totalPages || (number >= currentPage - 1 && number <= currentPage + 1)) {
                items.push(
                    <Link to="#" key={number}
                        className={`p-2 ${number === currentPage ? 'bg-amour-gray' : 'text-cloud-gray'} font-semibold rounded w-8 h-8 text-cloud-gray flex items-center justify-center`}
                        onClick={() => handleSelectPage(number)}>
                        {number}
                    </Link>
                );
            } else if (number === currentPage - 2 || number === currentPage + 2) {
                if (items[items.length - 1].key !== '...') {
                    items.push(
                        <li key={'...' + number} className='p-2 text-cloud-gray font-semibold rounded w-8 h-8 flex items-center justify-center'>...</li>
                    );
                }
            }
        }
        return items;
    };
    const handleNextClick = () => {
        if (currentPage < totalPages) {
            setStart(start + 1)
        }
    };
    const handlePriviousClick = () => {
        if (currentPage > 1) {
            setStart(start - 1)
        }
    };
    const handleSelectPage = (number) => {
        setStart(number)
    };
    const handleClearSearch = () => {
        setSearch('')
    }
    useEffect(() => {
        setCurrentPage(start);
    }, [start]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [memberrole, setMemberRole] = useState('')
    // const theme = useTheme();
    // const [Open, setOpen] = React.useState(false);
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handleClickClose = () => {
    //     setOpen(false);
    // };

    // manage member modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleOpenModal = (e) => {
        setIsModalOpen(true);
        setId(e?._id)
        setMemberRole(e.admin_status ? 'Admin' : 'Member')
    };
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    // delete member modal
    const [Deletemember, setIsDeletemember] = useState(false);
    const handleDeleteMember = (e) => {
        setIsDeletemember(true);
        setId(e)
    };
    const handleCloseMember = () => {
        setIsDeletemember(false);
    };
    const handleViewMember = (e) => {
        addData(null, null, null, e)
        navigate('/member/viewmember');
    }
    const handleSelctForChat = (e) => {
        let conversionId
        let toId = e._id
        let fromId = getData()?.user?._id
        if (fromId < toId) {
            conversionId = fromId + toId
        } else {
            conversionId = toId + fromId
        }
        dispatch(clearOldChat())
        addData(null, null, null, null, { toId, fromId, conversionId, name: e.name, profile_img: e.profile_img, isOnline: e.isOnline })
        navigate('/member/messages')
    }
    return (
        <>
            <div className='hidden md:block'>
                <Header title="User Management" />
            </div>
            <div className='p-4 sm:p-5 md:p-6 md:h-[calc(100%_-_97px)] flex flex-col h-full'>
                <div className='flex items-center justify-between pb-3 sm:pb-5'>
                    <h4 className='text-base sm:text-xl text-dark-black font-bold'>| Members List</h4>
                    <div className='md:flex hidden items-center gap-2'>
                        <Link href="#" to="/member/memberdetails" className={`border border-moon-gray p-[10px] w-11 h-11 flex items-center justify-center rounded-lg
                         ${location.pathname === '/member/memberdetails' ? 'bg-camo-green listmenuicon' : 'bg-white'}`}><CrossIcon className="w-5 h-5" /></Link>
                        <Link href="#" to="/member" className={` p-[10px] rounded-lg border border-moon-gray bg-white w-11 h-11 flex items-center justify-center ${location.pathname === '/member' ? 'bg-camo-green' : ''}`}><Listmenu className="w-5 h-5" /></Link>
                        <Link to="/member/addmember" className="border border-moon-gray bg-camo-green text-white min-w-[200px] flex items-center justify-center font-semibold p-[10px] rounded-lg">+ Add Member</Link>
                    </div>
                </div>
                <div className="overflow-x-auto bg-white p-4 sm:p-5 rounded-lg flex flex-col h-full">
                    <div className='pb-5 flex items-center justify-between text-lg'>
                        <div className='relative flex md:block  w-full md:w-auto'>
                            <div className='absolute top-1/2 -translate-y-1/2 left-4'>
                                <Search className="w-4 sm:w-5" />
                            </div>
                            <input type="text" placeholder='Search name, created by...' value={search} onChange={(e) => setSearch(e.target.value)} className='border border-moon-gray rounded-lg text-sm font-medium 2xl:min-w-[420px] pl-11 py-[10px] placeholder:text-cloud-gray w-full md:w-auto sm:min-w-[420px]' />
                            {/* {search && <div className='absolute top-1/2 -translate-y-1/2 right-3 mt-1'>
                                <button onClick={handleClearSearch}>  <CloseIcon className="w-5 h-5" /></button>
                            </div>} */}
                            <div className='block absolute top-1/2 right-4 -translate-y-1/2 md:hidden'>
                                {search ?
                                    <button onClick={handleClearSearch} className='mt-2'>  <CloseIcon className="w-5 h-5" /></button> :
                                    <>
                                        <Link href="#" onClick={handleClick} className='w-[18px]'>
                                            <FilterIcon className="w-5 h-5" />
                                        </Link>
                                    </>
                                }
                            </div>
                        </div>
                        <div className='hidden md:block'>
                            <Link href="#" onClick={handleClick} className=' text-dark-black border border-moon-gray flex items-center py-[10px] px-3 gap-2 rounded-lg leading-[normal] text-base font-medium'> <FilterIcon className="w-5 h-5" /> Filters</Link>
                        </div>
                    </div>
                    <div className='overflow-auto flex-1'>
                        <div className='grid md:grid-cols-2 2xl:grid-cols-3 gap-4' >
                            {memberlist?.length > 0 ? memberlist.map((item) => (
                                <div className='border-b md:border border-amour-gray rounded-lg pb-4 sm:pb-5 md:p-4 sm:mb-5 md:mb-0 gap-3 sm:gap-4 md:gap-6 flex justify-center flex-col usergridlist'>
                                    <div className='flex justify-between items-center gap-2 text-dark-black font-semibold text-sm md:text-base'>
                                        <div className='flex items-center gap-3'>
                                            <img className='w-8 h-8 object-cover rounded-full md:w-9 md:h-9' src={item?.user_id?.profile_img ? item?.user_id?.profile_img : 'https://hprojecttesting.s3.amazonaws.com/hunting_app/profile_pic/gwkKVE5715Im7ymb.jpg'} alt="" />
                                            {item?.user_id?.name}
                                        </div>
                                    </div>
                                    <div>
                                        <table>
                                            <tbody className='flex justify-center flex-col gap-4'>
                                                <tr className='flex sm:items-center flex-col sm:flex-row gap-1 sm:gap-2 md:gap-3'>
                                                    <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green word-break'>Members ID<span>:</span> </td>
                                                    <td className='text-dark-black font-semibold text-sm word-break'>{item?._id}</td>
                                                </tr>
                                                <tr className='flex sm:items-center gap-2 md:gap-3'>
                                                    <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>Member Role<span>:</span></td>
                                                    <td>
                                                        {item?.admin_status ?
                                                            <button className='bg-dark-light-green py-[6px] px-4 rounded-[63px] text-green font-semibold text-sm'>Admin
                                                            </button> :
                                                            <button className='bg-[#EDEEEC] text-[#686868] py-[6px] px-4 rounded-[63px]  font-semibold text-sm'>Member</button>}
                                                    </td>
                                                </tr>
                                                <tr className='flex sm:items-center gap-2 md:gap-3'>
                                                    <td className='xl:min-w-[150px] flex md:justify-between gap-1 items-center text-sm font-medium md:font-semibold text-cloud-gray md:text-camo-green'>
                                                        <div className='flex items-center gap-[10px] mt-2'>
                                                            <button className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white' onClick={() => handleSelctForChat(item?.user_id)}><Messages className='m-auto w-5 fill-[#363636]' /></button>
                                                            <button onClick={() => handleViewMember(item?._id)} className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white'><OpenEye className='m-auto w-5' /></button>
                                                            <button onClick={() => handleOpenModal(item)} className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white'><Usersetting className='m-auto w-5' />
                                                            </button>
                                                            <button onClick={() => handleDeleteMember(item?.user_id?._id)} className='h-[30px] rounded w-[30px] flex items-center justify-center border border-light-gray-white'><Deleteicons className='m-auto w-5' /></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )) : (<div className='col-span-4'>
                                <p className='text-center text-sm text-cloud-gray font-semibold'>Data not found!</p>
                            </div>
                            )}
                        </div>
                        <Managemember open={isModalOpen} handleClose={handleCloseModal} id={Id} role={memberrole} />
                        <Removemember open={Deletemember} handleClose={handleCloseMember} id={Id} />
                    </div>
                    <div className='hidden items-center justify-between pt-4 w-full md:flex'>
                        <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage > 1 ? handlePriviousClick : undefined}> <Leftarrow className="w-6" /> PREV</button>
                        <ul className='flex items-center'>
                            {paginationItems()}
                        </ul>
                        <button className='flex items-center text-cloud-gray text-sm font-semibold gap-2' onClick={currentPage < totalPages ? handleNextClick : undefined}>NXT <Rightlongarrow className="w-6" /></button>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Memberdetails;