import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import useSetupAxiosInterceptors from './Components/Loader/SetDefault';
import { Route, Routes } from 'react-router-dom/dist/umd/react-router-dom.development';
import Loginpage from './Page/Loginpage';
import Dashboardpage from './Page/Dashboardpage';
import { useLoading } from './Components/Loader/LoaderContext';
import Loader from './Components/Loader/Loader';
import Memberpage from './Page/Memberpage';
import './App.css';
import { getData } from './utils/db';
import socket from './utils/socket'
import { useDispatch } from 'react-redux';
import { appendMessageInChat } from './store/slice/chatSlice';
import { setCheckInUsers } from './store/slice/dashboardSlice';
import ProtectedRoute from './utils/private';
import DefaultPage from './Page/page404';


function App() {
  const dispatch = useDispatch()
  const isAuthenticated = () => {
    let data = getData()
    return data?.token !== null
  }
  useSetupAxiosInterceptors();
  const { isLoading } = useLoading();
  useEffect(() => {
    socket.connect()
    socket.on('connect', () => {
      console.log('Connected to socket server');
    });
    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });
    socket.on('sendMessage', (data) => {
      dispatch(appendMessageInChat(data))
    })
    socket.on('realTracking', (data) => {
      dispatch(setCheckInUsers(data?.show_location))
    })

    socket.emit('joinRoom', { fromId: getData()?.user?._id })
    // Clean up on unmount
    return () => {
      socket.disconnect();
    };
  }, [])
  return (
    <>
      {isLoading && <div className='absolute z-30 inset-0 bg-black bg-opacity-50 flex justify-center items-center'><Loader /></div>}
      <BrowserRouter>
        <Routes>
          <Route path="/dashboard/*" element={<ProtectedRoute> <Dashboardpage /></ProtectedRoute>} />
          <Route path="/member/*" element={<ProtectedRoute><Memberpage /></ProtectedRoute>} />
          <Route path="/" element={<Loginpage />} />
          <Route path="*" element={<DefaultPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
