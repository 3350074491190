import React, { useEffect, useState } from 'react';
import { Closeeye, Eye, Logoimage } from '../Assest/Allsvg';
import { Checkbox } from '@mui/material';
import loginuser from '../Assest/loginuser.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../Components/validaiton';
import { Link } from 'react-router-dom';
import { getData } from '../utils/db';
import { adminLogin, socialLogin } from '../store/slice/adminSlice';

import GoogleLoginButton from '../Components/GoogleLogin/GoolgeLogin';
import { GoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Correct default import

const clientId = process.env.REACT_APP_CLIENT_ID;

const LoginPageComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminError = useSelector((state) => state.admin.error);
  const [error, setError] = useState({});
  const [isCheck, setIsCheck] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      console.log('One Tap login successful', credentialResponse);
      const credential = credentialResponse.credential;
      const decoded = jwtDecode(credential); // Decode the JWT token correctly
      console.log("Login Success:", decoded);
      let data = {
        email: decoded?.email,
        uid: decoded?.jti,
        name: decoded?.name,
        fcm_token: [],
        picture: decoded?.picture
      }
      dispatch(socialLogin(data))
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/dashboard')
          }
        })
      // Handle the successful login here
    },
    onError: () => {
      console.error('One Tap login failed');
      // Handle login errors here
    },
    // Additional configuration options can be provided here
    auto_select: true, // Automatically prompt the user if they have a single session
  });
  useEffect(() => {
    let data = getData();
    if (data?.user) {
      setEmail(data?.user?.email);
      setPassword(data?.user?.password);
      setIsCheck(data?.user?.isCheck);
    }

  }, []);

  const handleLogin = () => {
    const obj = {};
    if (!email) {
      obj.email = 'Please enter email';
    } else {
      if (!validateEmail(email)) {
        obj.email = 'Please enter valid email';
      }
    }
    if (!password) {
      obj.password = 'Please enter password';
    }
    setError(obj);
    if (Object.keys(obj).length === 0) {
      dispatch(adminLogin({ email, password, isCheck }))
        .unwrap()
        .then((res) => {
          if (res.success) {
            navigate('/dashboard');
          }
        });
    }
  };

  return (
    <>
      <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 md:h-screen !m-0 !w-full gap-5 md:gap-10 lg:gap-0'>
        <div className='py-4 sm:py-5 flex flex-col justify-center overflow-auto'>
          <div className='px-4 md:px-7 lg:px-16'>
            <Link to="#">
              <Logoimage className="max-w-[180px] m-0 sm:max-w-[270px] mx-auto" />
            </Link>
            <div className='pt-2 sm:pt-6 md:pt-8 text-left flex flex-col gap-2'>
              <h3 className='text-2xl font-bold leading-[normal] text-dark-black'>Log in</h3>
              <h6 className='font-medium text-sm leading-[normal] text-cloud-gray'>
                Welcome to My G-Spot Outdoors. Please log in.
              </h6>
            </div>
            <div className='text-left pt-4 sm:pt-5 md:pt-10'>
              <h4 className='font-semibold pb-2 text-dark-black'>Email Address</h4>
              <div>
                <input
                  type="email"
                  placeholder="Enter email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm font-semibold'
                />
                {error.email && <p className='text-red text-sm pt-1'>{error.email}</p>}
              </div>
            </div>
            <div className='text-left pt-2 sm:pt-5 md:pt-7'>
              <h4 className='font-semibold pb-2 text-dark-black'>Password</h4>
              <div className='relative'>
                <input
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='border border-spring-rain rounded-lg w-full p-2.5 leading-[normal] text-cloud-gray placeholder:text-cloud-gray text-sm font-semibold'
                />
                <button
                  onClick={() => setShowPassword(!showPassword)}
                  className='absolute top-1/2 right-4 -translate-y-1/2'
                >
                  {!showPassword ? <Closeeye className='w-5' /> : <Eye className='w-5' />}
                </button>
              </div>
              {error.password && <p className='text-red text-sm pt-1'>{error.password}</p>}
            </div>
            <div className='flex justify-between items-center pt-3'>
              <span className='font-medium flex items-center gap-1 md:gap-2 text-sm md:text-base'>
                <Checkbox checked={isCheck} onChange={() => setIsCheck(!isCheck)} />
                Remember me
              </span>
            </div>
            <button
              className='bg-camo-green text-white w-full rounded-lg p-2 font-semibold mt-4 md:mt-8'
              onClick={handleLogin}
            >
              Log in
            </button>
          </div>
          {adminError && <p className='text-red text-sm mt-2'>{adminError}</p>}

          <div className='mt-5 text-center'>
            <span className='text-[#47543C] font-medium'>Or</span>
            <div className='flex justify-center mt-5'>
              <GoogleLoginButton />
            </div>
          </div>
        </div>
        <div className='w-full h-full hidden md:block 2xl:col-span-2'>
          <img src={loginuser} alt="Login" className='h-full object-cover' />
        </div>
      </div>
    </>
  );
};

const Loginpage = () => {
  return (
    <GoogleOAuthProvider clientId={clientId}>
      <LoginPageComponent />
    </GoogleOAuthProvider>
  );
};

export default Loginpage;
