import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { useMediaQuery } from '@mui/material';

const MapComponentDashboard = ({ markers, polyog, mapMax, small }) => {
    const [customIcons, setCustomIcons] = useState({});

    useEffect(() => {
        const createCustomIcon = (imageUrl, borderColor) => {
            const canvas = document.createElement('canvas');
            const size = 40; // size of the custom icon
            canvas.width = size;
            canvas.height = size;
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.crossOrigin = 'Anonymous'; // This is important for handling CORS
            img.src = imageUrl;
            img.onload = () => {
                // Draw border
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
                ctx.fillStyle = borderColor;
                ctx.fill();
                ctx.closePath();
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2 - 3, 0, Math.PI * 2);
                ctx.clip();
                ctx.drawImage(img, 3, 3, size - 6, size - 6);
                ctx.closePath();
                setCustomIcons(prevIcons => ({ ...prevIcons, [imageUrl]: canvas.toDataURL() }));
            };
        };

        markers?.forEach(marker => {
            if (marker.user_id?.profile_img && !customIcons[marker.user_id?.profile_img]) {
                createCustomIcon(marker.user_id?.profile_img, marker?.color); // Use the desired border color
            }
        });
    }, [markers, customIcons]);

    const polygonOptions = polyog ? {
        paths: polyog?.coordinates[0]?.map(coord => ({ lng: coord[0], lat: coord[1] })),
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.35,
    } : {};

    const center = useMemo(() => {
        if (markers && markers.length > 0) {
            return { lat: markers[0].latLong.coordinates[1], lng: markers[0].latLong.coordinates[0] };
        }

        if (polygonOptions.paths && polygonOptions.paths.length > 0) {
            const firstPoint = polygonOptions.paths[0];
            return { lat: firstPoint.lat, lng: firstPoint.lng };
        }

        return { lat: 0, lng: 0 };
    }, [markers, polygonOptions]);

    const mapSize = useMediaQuery('(min-width: 768px)') ? mapMax : small;

    return (
        <div className='w-full h-full'>
            <LoadScript googleMapsApiKey="AIzaSyBWK8aI8XxpYEcwbvC9jDvo_7Am4-DrpHA">
                <GoogleMap
                    mapContainerStyle={mapSize}
                    center={center}
                    zoom={15}
                >
                    {markers?.length > 0 && markers.map(marker => (
                        <Marker
                            key={marker._id}
                            position={{ lat: marker.latLong.coordinates[1], lng: marker.latLong.coordinates[0] }}
                            icon={customIcons[marker.user_id?.profile_img]}
                        />
                    ))}
                    {polyog && <Polygon options={polygonOptions} />}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapComponentDashboard;
