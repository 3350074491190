import React from 'react';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { getData } from '../../utils/db';
import { removeMember, removeMemberFromList } from '../../store/slice/memberSlice';
import { useNavigate } from 'react-router-dom';
import { Closeicon } from '../../Assest/Allsvg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const Removemember = ({ open, handleClose, id, type }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleDelete = () => {
        const clubId = getData()?.club_id
        dispatch(removeMemberFromList({ id, clubId }))
        handleClose()
        dispatch(removeMember({ club_id: clubId, user_id: id })).unwrap().then((res) => {
            if (res.success) {
                if (type && type === 1) {
                    navigate('/member')
                }
            }
        })
    }
    return (
        <div>
            <Modal
                className='remove-modal'
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='w-[92%] sm:w-[500px] rounded-[15px] p-4 sm:p-6 !border-none'>
                    <div className='flex items-center justify-center pb-5 relative'>
                        <h4 id="modal-modal-title" className='text-[#141414] text-xl sm:text-2xl font-semibold'>
                            Remove Member!
                        </h4>
                        <button onClick={handleClose} className='absolute right-0 top-0'> <Closeicon /></button>
                    </div>
                    <div>
                        <h6 className='text-[#363636] sm:text-lg font-semibold pb-2 text-center max-w-[350px] mx-auto'>
                            Are you sure you want to remove member from club ?</h6>
                        <div className='flex justify-center items-center gap-5 pt-3'>
                            <button className='bg-[#EDEEEC] text-camo-green font-semibold sm:text-lg min-w-[130px] sm:min-w-[177px] py-2 rounded-lg' onClick={handleClose}>Cancel</button>
                            <button className='bg-dark-red text-white font-semibold sm:text-lg min-w-[130px] sm:min-w-[177px] py-2 rounded-lg' onClick={handleDelete}>Remove</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default Removemember;
