import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { getData } from './db';

const ProtectedRoute = ({ children }) => {
    let data = getData().token
    let location = useLocation();

    if (!data) {
        return <Navigate to="/" state={{ from: location }} replace />
    }
    return children

};

export default ProtectedRoute;