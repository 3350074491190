import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, LoadScript, Marker, Polygon } from '@react-google-maps/api';
import { useMediaQuery } from '@mui/material';

const MapComponent = ({ marker, polyog, mapMax, small, member }) => {
    const [customIcons, setCustomIcons] = useState({});
    useEffect(() => {
        const createCustomIcon = (imageUrl, borderColor) => {
            console.log("Creating icon for image URL:", imageUrl);
            const canvas = document.createElement('canvas');
            const size = 40;
            canvas.width = size;
            canvas.height = size;
            const ctx = canvas.getContext('2d');

            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.src = imageUrl;
            img.onload = () => {
                console.log("Image loaded successfully");
                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
                ctx.fillStyle = borderColor;
                ctx.fill();
                ctx.closePath();

                ctx.beginPath();
                ctx.arc(size / 2, size / 2, size / 2 - 3, 0, Math.PI * 2);
                ctx.clip();
                ctx.drawImage(img, 3, 3, size - 6, size - 6);
                ctx.closePath();

                setCustomIcons(prevIcons => ({ ...prevIcons, [imageUrl]: canvas.toDataURL() }));
            };
            img.onerror = () => {
                console.error("Image failed to load:", imageUrl);
            };
        };
        if (member?.user_id?.profile_img && !customIcons[member?.user_id?.profile_img]) {
            createCustomIcon(member?.user_id?.profile_img, member?.color);
        }

    }, [member, customIcons]);

    let polygonOptions = {};
    if (polyog) {
        polygonOptions = {
            paths: polyog?.coordinates[0]?.map(coord => ({ lng: coord[0], lat: coord[1] })),
            strokeColor: "#FF0000",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.35,
        };
    }

    const center = useMemo(() => {
        if (marker) {
            return { lat: marker[1], lng: marker[0] };
        }
        if (polygonOptions.paths && polygonOptions.paths.length > 0) {
            const firstPoint = polygonOptions.paths[0];
            return { lat: firstPoint.lat, lng: firstPoint.lng };
        }

        return { lat: 0, lng: 0 }; // Default center if no marker and no polygon paths
    }, [marker, polygonOptions]);

    const mapSize = useMediaQuery('(min-width: 768px)') ? mapMax : small;

    return (
        <div className='w-full h-full'>
            <LoadScript className="heymap" googleMapsApiKey="AIzaSyBWK8aI8XxpYEcwbvC9jDvo_7Am4-DrpHA">
                <GoogleMap
                    mapContainerStyle={mapSize}
                    className="w-full h-full"
                    center={center}
                    zoom={10}
                >
                    {marker && <Marker position={center} icon={customIcons[member?.user_id?.profile_img]} />}
                    {polyog && <Polygon options={polygonOptions} />}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default MapComponent;
