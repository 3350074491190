import React, { useEffect, useState } from 'react';
import { Popover } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { userNotification } from '../../store/slice/dashboardSlice';
import moment from 'moment';

const Notificationpopup = ({ anchorEl, handleClose, className }) => {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(5)
    const [read, setRead] = useState(0)
    const notification = useSelector((state) => state.dashboard.notification)
    // const notificationCount = useSelector((state) => state.dashboard.notificationCount);
    useEffect(() => {
        dispatch(userNotification({ page, limit, read }))
    }, [dispatch, page, limit, read]);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    function formatTimestamp(timestamp) {
        const now = moment();
        const date = moment(timestamp);
        if (!date.isValid()) {
            return 'Invalid date';
        }
        if (date.isSame(now, 'day')) {
            return 'Today';
        } else if (date.isSame(now.clone().subtract(1, 'day'), 'day')) {
            return 'Yesterday';
        } else {
            return date.format('MMMM D, YYYY');
        }
    }
    const handleAddLimit = () => {
        setLimit(limit + 5)
    }
    return (
        <>
            <Popover
                className={`filtermodal notification-popup  ${className}`}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div className='border-b border-b-amour-gray pt-4 max-h-[500px]'>
                    <div className='flex justify-between pb-5 px-4'>
                        <h3 className='text-[#000000] font-semibold text-lg leading-[normal]'>Notification</h3>
                        {/* <button><Settingicon /></button> */}
                    </div>
                    {notification?.length > 0 ? (
                        notification.map((data, index) => (
                            <div key={index}>
                                {/* Render the timestamp for each notification group */}
                                <h5 className='bg-[#F8F8F8] border-t border-t-[#F0F0F0] px-5 py-[6px] text-[#000000] font-medium'>
                                    {formatTimestamp(data.parsedDate)}
                                </h5>
                                {/* Render individual notifications */}
                                {data.notification.map((item, idx) => (
                                    <div key={idx} className='flex flex-col sm:flex-row justify-between p-4 sm:py-5 sm:px-5 border-b border-b-[#F0F0F0]'>
                                        <div className='flex items-start gap-4'>
                                            <img src={item.user?.profile_img} alt='' className='w-9 h-9 rounded-full' />
                                            <p className='text-sm max-w-[250px]'>
                                                <b>{item.title}</b>
                                                <span className='block overflow-hidden text-ellipsis whitespace-nowrap line-clamp-3'>{item.discription}</span>
                                            </p>
                                        </div>
                                        <span className='text-[#363636] font-medium text-end flex-shrink-0 sm:text-start'>
                                            {moment(item.createdAt).fromNow().replace('a ', '1 ')}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))

                    ) : (
                        <h5>Notifications not found</h5>
                    )}
                    {notification?.length > 0 && <button className='bg-[#F8F8F8] border-t border-t-[#F0F0F0] px-5 py-[6px] text-[#000000] font-medium w-full' onClick={handleAddLimit}>View more</button>}
                </div>
            </Popover>
        </>
    )
}
export default Notificationpopup;