import React from 'react';
import { Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { Closeicon } from '../../Assest/Allsvg';
import successicon from '../../Assest/successicon.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const Successmodal = ({ open, handleClose }) => {
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='w-[92%] sm:w-[620px] rounded-[15px] py-5 px-4 sm:px-6 !border-none'>
                    <div className='flex items-center justify-end pb-5'>
                        <button onClick={handleClose} > <Closeicon /></button>
                    </div>
                    <div className='text-center'>
                        <img className='mx-auto w-10 mb-2' src={successicon} alt="" />
                        <h3 className='text-[#141414] font-semibold text-xl sm:text-2xl pb-1' >Success!</h3>
                        <h6 className='text-[#686868] sm:text-lg font-semibold pb-4 text-center mx-auto'>Successfully Added member to the club.</h6>
                        <button className='bg-camo-green text-white font-semibold sm:text-lg min-w-[140px] sm:min-w-[177px] py-2 rounded-lg' onClick={() => handleClose()}>Awesome!</button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default Successmodal;
