import React from 'react';
import Sidebar from '../Components/Sidebar/Sidebar';
import Dashboard from '../Components/Dashboard/Dashboard';
import Recentactivity from '../Components/Recentactivity/Recentactivity';
import { Route, Routes } from 'react-router-dom';
import Alertsviewmore from '../Components/Alertsviewmore/Alertsviewmore';

const Dashboardpage = () => {
    return (
        <div>
            <div className='bg-light-gray h-screen'>
                <div>
                    <Sidebar />
                </div>
                <div className='w-full lg:w-[calc(100%_-_300px)] xl:w-[calc(100%_-_350px)] ml-auto h-full sm:overflow-auto flex flex-col'>
                    <Routes>
                        <Route path="/*" element={ <Dashboard />} />
                        <Route path="/recentactivity" element={<Recentactivity />} />
                        <Route path="/alertsviewmore" element={<Alertsviewmore />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}
export default Dashboardpage;