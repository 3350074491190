import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import { logOut } from '../../utils/db';
import { Category, Clubmanage, Communications, Logoimage, Logout, Mapview, Sefty, Userimage } from '../../Assest/Allsvg';

const SidebarDrawer = ({ isDrawerOpen, handleDrawerClose }) => {
    const location = useLocation();
    let navigate = useNavigate();
    const handleLogut = async (event) => {
        event.preventDefault();
        try {
            await logOut(); // Await logOut to ensure it completes
            navigate('/');
            logout2()
        } catch (error) {
            console.error('Logout failed:', error); // Handle any errors during logout
        }
    };
    const logout2 = () => {
        navigate('/')
    }
    const MembersRoutes = [
        '/member',
        '/member/addmember',
        '/member/memberdetails',
    ];
    const isActiveMembers = MembersRoutes.some(route => location.pathname === route);
    return (
        <>
            <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose} className='mobile-menu-drawer'>
                <div className="flex flex-col justify-between overflow-auto sidebar_main pt-4 h-full box-border md:min-w-[350px] md:bg-white">
                    <div>
                        <Link to="">
                            <Logoimage className="mx-auto w-[150px]" />
                        </Link>
                        <ul className="sidebar_menu flex flex-col gap-1 pt-4 pr-[10px]">
                            <li><Link to="/dashboard" className={`flex items-center text-sm font-semibold text-cloud-gray gap-[10px] py-2 pl-5 md:text-lg md:py-3  ${location.pathname === '/dashboard' ? 'border-r-4 border-[#47543C] bg-milk-white font-semibold rounded-tr-lg rounded-br-lg' : 'border-r-4 border-transparent'
                                }`}>
                                <Category className="w-5" /> Dashboard</Link></li>
                            <li><Link to="/member" className={`flex items-center text-sm font-semibold text-cloud-gray py-2 pl-5 gap-[10px] ${isActiveMembers ? 'border-r-4 border-[#47543C] bg-milk-white rounded-tr-lg font-semibold rounded-br-lg' : 'border-r-4 border-transparent'
                                }`}> <Userimage className="w-5" />Members</Link>
                            </li>
                            <li><Link to="#" className='flex items-center text-sm font-semibold text-cloud-gray gap-[10px] py-2 pl-5'>
                                <Mapview className="w-5" />Map Management</Link>
                            </li>
                            <li>
                                <Link to="#" className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base'><Communications className="w-5" />Communications</Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base '
                                >
                                    <Sefty className="w-5" />Safety and Alerts
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="#"
                                    className='flex items-center font-semibold text-cloud-gray gap-2 py-3 xl:py-4 px-5 text-sm md:text-base'
                                >
                                    <Clubmanage className="w-5" />Club Management
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <ul>
                        <li>
                            <Link onClick={handleLogut} className="flex items-center justify-center border-t border-t-light-dark-green bg-light-green text-sm font-medium py-3 gap-[10px]"> <Logout /> Log Out
                            </Link>
                        </li>
                    </ul>
                </div>
            </Drawer>
        </>
    )
}
export default SidebarDrawer;
