import React, { useEffect, useState } from 'react';
import { Modal, Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { manageMember } from '../../store/slice/memberSlice';
import { Closeicon } from '../../Assest/Allsvg';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const Managemember = ({ open, handleClose, id, role }) => {
    const dispatch = useDispatch()
    // const navigate = useNavigate();
    const [status, setStatus] = useState('')
    useEffect(() => {
        setStatus(role)
    }, [role])
    const handleChange = (event) => {
        setStatus(event.target.value);
    };
    const handleSubmit = () => {
        dispatch(manageMember({ memberId: id, status }))
            .unwrap()
            .then((res) => {
                if (res.success) {
                    handleClose()
                }
            })
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className='w-[95%] sm:w-[620px] rounded-[15px] pt-3 pb-5 px-4 sm:px-6 !border-none'>
                    <div className='flex items-center justify-between pb-5'>
                        <h4 id="modal-modal-title" className='text-[#141414] text-xl sm:text-2xl font-semibold'>
                            Manage Member!
                        </h4>
                        <button onClick={handleClose} > <Closeicon /></button>
                    </div>
                    <div>
                        <h6 className='text-[#363636] sm:text-lg font-semibold pb-2'>Member Role</h6>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={status}
                            onChange={handleChange}
                        >
                            <div className='border border-[#BEBEBE] flex items-center p-3 sm:py-4 sm:px-5 rounded-lg sm:rounded-2xl gap-3'>
                                <FormControlLabel className='!m-0' value="Admin" control={<Radio value="Admin" className='member-radio !p-0' />} />
                                <div>
                                    <h6 className='text-[#363636] font-medium'>Admin</h6>
                                    <span className='text-[#686868]'>Can add member, configure, and manage</span>
                                </div>
                            </div>
                            <div className='border border-[#BEBEBE] flex items-center p-3 sm:py-4 sm:px-5 rounded-lg sm:rounded-2xl 
                            gap-4 mt-[10px]'>
                                <FormControlLabel className='!m-0' value="Member" control={<Radio className='member-radio !p-0' />} />
                                <div>
                                    <h6 className='text-[#363636] font-medium'>Member</h6>
                                    <span className='text-[#686868]'>Can only member</span>
                                </div>
                            </div>
                        </RadioGroup>
                        <div className='flex justify-center items-center gap-5 pt-8'>
                            <button className='bg-[#EDEEEC] text-camo-green font-semibold sm:text-lg min-w-[130px] sm:min-w-[177px] py-2 rounded-lg' onClick={handleClose} >Cancel</button>
                            <button className='bg-camo-green text-white font-semibold sm:text-lg min-w-[130px] sm:min-w-[177px] py-2 rounded-lg' onClick={handleSubmit}>Save</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}
export default Managemember;
